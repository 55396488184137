import React, {Fragment} from "react";
import InfoArea from "../../../InfoArea/InfoArea";
import Timeline from "@material-ui/icons/Error";
import Danger from "../../../Typography/Danger";
import Button from "components/CustomButtons/Button.js";

const NewsletterFailed = ({ classes, clear }) => (
    <Fragment>
        <InfoArea
            className={classes.infoArea}
            title="Error"
            description={
                <Danger>
                    Could not add you to our mailing list..
                    <br/>
                    Have you registered previously?
                </Danger>
            }
            icon={Timeline}
            iconColor="danger"
        />
        <div className={classes.textCenter}>
        <Button color={'danger'} onClick={() => clear()}>
            Close
        </Button>
        </div>
    </Fragment>
)
export default NewsletterFailed;
