import {HIDE_SIGN_UP_MODAL, SHOW_SIGN_UP_MODAL} from "../../actions/signupmodal/signupmodalActions";

const defaultState = {
    showModal: false
}

const signupModalReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SHOW_SIGN_UP_MODAL:
            return {
                ...state,
                showModal: true
            }

        case HIDE_SIGN_UP_MODAL:
            return {
                ...state,
                showModal: false
            }
        default:
            return state
    }
}

export default signupModalReducer;
