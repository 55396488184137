import React, {Fragment, useEffect, useState} from "react";
import HeaderMargin from "../components/rccgjc/header-margin/header-margin";
import {useDispatch} from "react-redux";
import {setThemeForHeader} from "../actions/app/appActions";
import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import CustomLinearProgress from "../components/CustomLinearProgress/CustomLinearProgress";

import JCSubscribeToNewsletter from "../components/rccgjc/modals/jc-subscribe-to-newsletter";
import {useQuery} from "@apollo/client";
import {STREAMING_EVENT} from "../graphql-config/query/event";

import OfferingModal from "../components/rccgjc/giving/offering-modal";

const useStyles = makeStyles(teamsStyle);

const StreamPage = () => {

    const { data } = useQuery(STREAMING_EVENT);

    const [streamEvent, setStreamEvent] = useState({});
    const [status, setStatus] = useState('failed');

    useEffect(() => {
            if (data && data.ongoingEvent) {
                setStreamEvent(data.ongoingEvent);
                setStatus("done");
            }
        },
        [data]);

    const dispatch = useDispatch();
    useEffect(() => {
            dispatch(setThemeForHeader('white'))
        },
        [])

    const classes = useStyles();

    return (
        <div>
            <HeaderMargin/>
            <div className="cd-section" >
                <div className={classes.team}>
                    <div className={classes.container}>
                        {
                            status === 'loading' ?
                                <Fragment>
                                    <GridContainer>
                                        <GridItem
                                            xs={12}
                                            sm={8}
                                            md={8}
                                            className={
                                                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                                            }
                                        >
                                            <div style={{
                                                marginTop: '15rem'
                                            }}>
                                                <Card profile plain>
                                                    <CardBody>
                                                        <CustomLinearProgress
                                                            variant="indeterminate"
                                                            color="info"
                                                            value={60}
                                                        />
                                                        <h2 className={'title'}>loading...</h2>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <HeaderMargin marginWithUnit={'15rem'}/>
                                        </GridItem>
                                    </GridContainer>
                                </Fragment>
                                :
                                status === 'done' ?
                                    <Fragment>
                                        <div className={classes.textCenter}>
                                            <iframe
                                                src={`${streamEvent.streamingLink}`}
                                                width="560" height="315" style={{
                                                    border: 'none',
                                                    overflow: 'hidden'
                                            }}
                                                scrolling="no" frameBorder="0" allowtransparency="true"
                                                allowFullScreen/>
                                        </div>
                                        <GridContainer justifyContent={'center'}>
                                            <GridItem xs={12} sm={5} md={5}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center'
                                                }}>
                                                    <div>
                                                            <OfferingModal event={streamEvent}/>
                                                    </div>
                                                </div>
                                                <HeaderMargin marginWithUnit={'9rem'}/>
                                            </GridItem>
                                        </GridContainer>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <GridContainer>
                                            <GridItem
                                                xs={12}
                                                sm={8}
                                                md={8}
                                                className={
                                                    classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                                                }
                                            >
                                                <div style={{
                                                    marginTop: '9rem'
                                                }}>
                                                    <Card profile plain>
                                                        <CardBody>
                                                            <h2 className={'title'}>No Messages streaming at this time</h2>
                                                            <h4>
                                                                please reload this page in a few minutes
                                                            </h4>
                                                            <JCSubscribeToNewsletter buttonColor={'danger'}/>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                                <HeaderMargin marginWithUnit={'10rem'}/>
                                            </GridItem>
                                        </GridContainer>
                                    </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default StreamPage;
