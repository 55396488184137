import {
    gql
} from "@apollo/client";

export const SAVE_STUDY_NOTE = gql`
    mutation createStudyNote($request:StudyNoteRequest) {
        saveNote(request:$request) {
        studyNoteId
        notes
        noteTitle
    }
}
`;

export const SEND_STUDY_NOTE = gql`
    mutation createStudyNote($request:StudyNoteRequest) {
        sendNote(request:$request)
}
`;

export const RETRIEVE_NOTE = gql`
    query retrieveNote($noteId: String!) {
        retrieveNote(noteId:$noteId) {
        studyNoteId
        notes
        noteTitle
        email
        firstName
    }
}
`;