import {LOAD_WORD_FOR_THE_MONTH, SET_ERROR, SET_STATUS} from "../../actions/wordForTheMonth/wordForTheMonthActions";

const defaultState = {
    wordForTheMonth: {},
    status: '',
    error: {}
}

const wordForTheMonthReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_WORD_FOR_THE_MONTH:
            return {
                ...state,
                status: 'done',
                wordForTheMonth: action.payload
            }
        case SET_STATUS:
            return {
                ...state,
                status: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return defaultState
    }

}

export default wordForTheMonthReducer;
