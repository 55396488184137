import {
    gql
} from "@apollo/client";

export const ALL_EVENTS = gql`
    query allEvents {
        allEvents {
        id
        name
        startTime
        endTime
        date
  }
}
`;

export const STREAMING_EVENT = gql`
   query onGoing {
     ongoingEvent {
     streamingLink
     id
  }
}
`;

export const MP3_MESSAGES = gql`
    query mp3Messages {
        allMp3Messages {
        speaker
        messageLink
        messageTitle
        eventName
        date
    }
}
`;
