export const SHOW_SIGN_UP_MODAL = 'SHOW_SIGN_UP_MODAL';
export const HIDE_SIGN_UP_MODAL = 'HIDE_SIGN_UP_MODAL';

const showSignupModal = () => ({ type: SHOW_SIGN_UP_MODAL })
const hideSignupModal = () => ( { type: HIDE_SIGN_UP_MODAL })

export const showModal = () => dispatch => {
    dispatch(showSignupModal())
}

export const hideModal = () => dispatch => {
    dispatch(hideSignupModal())
}
