import {
    gql
} from "@apollo/client";

export const LANDING_PAGE = gql`
query landingpage {
  landingPageDetails {
    status
    details {
      name
      link
    }
  }
}
`;
