import React, {Suspense} from "react";
import './App.css';

import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import JCHeader from "./components/rccgjc/header/jc-header";
import CustomLinearProgress from "./components/CustomLinearProgress/CustomLinearProgress";
import HeaderMargin from "./components/rccgjc/header-margin/header-margin";
import JCHeaderLinks from "./components/rccgjc/header/jc-header-links";
import {useSelector} from "react-redux";
import SocialMediaFooter from "./components/rccgjc/footer/social-media-footer";
import JCFooter from "./components/rccgjc/footer/jc-footer";
import LandingPage from "./pages/Landingpage";
import RegistrationPage from "./pages/RegistrationPage";
import RccgPage from "./pages/RccgPage";
import AboutUsPage from "./pages/AboutUsPage";
import ServicesPage from "./pages/ServicesPage";
import MinistriesPage from "./pages/MinistriesPage";
import ContactUsPage from "./pages/ContactUsPage";
import ReplayPage from "./pages/ReplayPage";
import StreamPage from "./pages/StreamPage";
import GivingPage from "./pages/GivingPage";
import TithePage from "./pages/TithePage";
import StudyPage from "./pages/StudyPage";
import RetrieveNotePage from "./pages/RetrieveNotePage";
import ErrorPage from "./pages/ErrorPage";
import PrivacyAndTermsPage from "./pages/PrivacyAndTermsPage";
import IEErrorPage from "./pages/IEErrorPage";
import generatedGitInfo from './generatedGitInfo.json'

const isIE = /*@cc_on!@*/false || !!document.documentMode;

function App({...rest}) {
  const { headerTheme } = useSelector(state => state.rccgjc)

  if (isIE) {
    return <IEErrorPage/>
  }
  return (
      <Router>
        <Suspense fallback={<div>
          <HeaderMargin marginWithUnit={'24rem'}/>
          <div style={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            <div style={{
              padding: '4rem'
            }}>
              <CustomLinearProgress
                  variant="indeterminate"
                  color="info"
                  value={60}
              />

            </div>
            <div>
              <h2>
                loading...
              </h2>
            </div>
            <HeaderMargin marginWithUnit={'20rem'}/>
          </div>
        </div>}>
          <JCHeader
              color={headerTheme}
              brand="Jesus Connections"
              links={<JCHeaderLinks dropdownHoverColor="info"/>}
              fixed
              changeColorOnScroll={{
                height: 300,
                color: "info"
              }}
              {...rest}
          />

          <Routes>
            <Route path={'/'} exact element={<LandingPage/>}/>
            <Route path={'/register'} exact element={<RegistrationPage/>}/>
            <Route path={'/rccg'} exact element={<RccgPage/>}/>
            <Route path={'/about-us'} exact element={<AboutUsPage/>}/>
            <Route path={'/services'} exact element={<ServicesPage/>}/>
            <Route path={'/ministries/:ministry'} exact element={<MinistriesPage/>}/>
            <Route path={'/contact-us'} exact element={<ContactUsPage/>}/>
            <Route path={'/replay'} exact element={<ReplayPage/>}/>
            <Route path={'/streaming'} exact element={<StreamPage/>}/>
            <Route path={'/giving'} exact element={<GivingPage/>}/>
            <Route path={'/tithe'} exact element={<TithePage/>}/>
            <Route path={'/study'} exact element={<StudyPage/>}/>
            <Route path={'/retrievenotefromid'} exact element={<RetrieveNotePage/>}/>
            <Route path={'/privacy'} exact element={<PrivacyAndTermsPage/>}/>
            <Route path={'*'} element={<ErrorPage/>}/>
          </Routes>
          <SocialMediaFooter/>
          <JCFooter/>
          {
            process.env.NODE_ENV === 'development' ?
                <>
                  <div style={{ textAlign: 'center' }}>
                    development info: <small>current branch is {generatedGitInfo.gitBranch}</small>
                  </div>
                </>

                :null
          }

        </Suspense>
      </Router>
  );
}

export default App;
