import React, {useState} from "react";
import Card from "../../../Card/Card";

import JCGridItem from "../../grid/jc-grid-item";
import FormFooter from "./footer";
import FormHeader from "./form-header";
import FormBody from "./form-body";
import {isValidEmail} from "../../../../validations";
import Danger from "../../../Typography/Danger";
import CustomLinearProgress from "../../../CustomLinearProgress/CustomLinearProgress";
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem";
import CardBody from "../../../Card/CardBody";
import Info from "../../../Typography/Info";
import Success from "../../../Typography/Success";
import Button from "../../../CustomButtons/Button";
import {useMutation} from "@apollo/client";
import {CONTACT_US} from "../../../../graphql-config/query/contact-us";

const ContactForm = ({ classes }) => {

    const [checked, setChecked] = useState(false);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [emailError, setEmailErrorMessage] = useState('')
    const [messageError, setMessageErrorMessage] = useState('')
    const [formFilled, setFormFilled] = useState(false)
    const [status, setStatus] = useState('')

    const [contactUs] = useMutation(CONTACT_US);

    const clear = () => {
        setStatus('')
        setFirstName('')
        setLastName('')
        setEmail('')
        setFormFilled(false)
        setStatus('')
        setMessage('')
        setEmailErrorMessage('')
    }

    const handleFirstNameChange = event => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = event => {
        setLastName(event.target.value)
    }

    const handleEmailChange = event => {
        setEmailErrorMessage('')
        setEmail(event.target.value)
    }

    const handleMessageChange = event => {
        setMessageErrorMessage('')
        setMessage(event.target.value)
    }

    const send = () => {

        if (!isValidEmail(email)) {
            setEmailErrorMessage("We'll need your email in order to respond")
            return
        }

        if (message.length < 10) {
            setMessageErrorMessage("We'll need you to fill in atleast 10 words")
            return
        }

        setFormFilled(true)
        if (!checked) {
            return
        }

        contactUs({
            variables: {
                request: {
                    firstName,
                    lastName,
                    email,
                    message,
                    checked
                }
            },
            onCompleted(data) {
                console.log(data)
                setStatus('done');
            },
            onError(error) {
                console.log(error)
                setStatus('failed');
            }
        })
    }

    return <JCGridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
        <Card className={classes.card1}>
            <form>
                <FormHeader classes={classes}/>
                {
                    status === 'loading' ?
                        <div>
                            <CardBody>
                                <GridContainer justifyContent={'center'}>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <CustomLinearProgress
                                            variant="indeterminate"
                                            color="info"
                                            value={60}
                                        />
                                        <Info>
                                            one moment please
                                        </Info>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </div>
                        :
                        status === 'done' ?
                            <div>
                                <CardBody>
                                    <GridContainer justifyContent={'center'}>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <Success>
                                                We've sent your message and we'll send you a response as soon as we can.
                                            </Success>

                                            <Button color="info" className={classes.textCenter} onClick={clear}>
                                                close
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </div>
                            :
                        <div>
                            <FormBody classes={classes}
                                      firstName={firstName} handleFirstNameChange={handleFirstNameChange}
                                      lastName={lastName} handleLastNameChange={handleLastNameChange}
                                      email={email} handleEmailChange={handleEmailChange} emailError={emailError}
                                      message={message} handleMessageChange={handleMessageChange} messageError={messageError}/>
                            <FormFooter classes={classes} checked={checked} setChecked={setChecked} send={send}/>
                        </div>
                }

                {
                    formFilled && !checked ?
                        <div className={classes.textCenter}>
                            <Danger>
                                please verify you're not a robot
                            </Danger>
                        </div>

                        :
                        null
                }

            </form>
        </Card>
    </JCGridItem>
}

export default ContactForm;
