import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Muted from "components/Typography/Muted.js";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";
import pastortutu from 'images/_MG_1201-Edit.jpeg'
import Button from "../../CustomButtons/Button";
import { useDispatch } from "react-redux";

import {getWordForTheMonth} from "./wordForTheMonthRequests";
import {SHOW_SIGN_UP_MODAL} from "../../../actions/signupmodal/signupmodalActions";
import {Link} from "react-router-dom";

const useStyles = makeStyles(testimonialsStyle);

const WordForTheMonth = ({ ...rest }) => {
    const [status, setStatus] = useState('');
    const [wordForTheMonth, setWordForTheMonth] = useState({});

    const dispatch = useDispatch();

    const classes = useStyles();
    const load = () => {
        getWordForTheMonth()
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw Error(response.statusText);
                }
            })
            .then(resp => {
                setStatus('done')
                setWordForTheMonth(resp)
            })
            .catch(() => {
                setStatus('failed')
            })
    }

    useEffect(()=> {
        load()
    },
        [])

    if (status === 'failed' || status === '') {
        return null
    }
    return (
        <div className="cd-section" {...rest}>
            <div
                className={
                    classes.testimonials +
                    " " +
                    classes.sectionDark +
                    " " +
                    classes.testimonial2
                }
            >
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <div>
                                <Card testimonial plain className={classes.card2}>
                                    <CardAvatar testimonial plain>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <img src={pastortutu} alt="..." />
                                        </a>
                                    </CardAvatar>
                                    <CardBody plain>
                                        <h5 className={classes.cardDescription}>
                                            { wordForTheMonth.preview }
                                        </h5>
                                        <h4 className={classes.cardTitle}>Tutu Olutayo</h4>
                                        <Muted>
                                            <h6>Senior Pastor</h6>
                                        </Muted>
                                    </CardBody>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            <div>
                                                <Button color={'info'} onClick={() => dispatch({ type: SHOW_SIGN_UP_MODAL })}>
                                                    signup
                                                </Button>
                                            </div>

                                            <div style={{
                                                marginLeft: '3px'
                                            }}>
                                                <Button color={'danger'}>
                                                    <Link to={'/word-for-the-month'} style={{
                                                        color: 'white'
                                                    }}>
                                                        full text
                                                    </Link>
                                                </Button>
                                            </div>
                                        </div>

                                </Card>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}

export default WordForTheMonth;
