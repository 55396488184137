import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";


import Close from "@material-ui/icons/Close";
import Secure from "@material-ui/icons/Lock";
import Data from "@material-ui/icons/DataUsage";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import style from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import HeaderMargin from "../header-margin/header-margin";
import {isValidEmail} from "../../../validations";
import Danger from "../../Typography/Danger";
import Payment from "../stripe/payment";
import CardBody from "../../Card/CardBody";
import CustomLinearProgress from "../../CustomLinearProgress/CustomLinearProgress";
import {useMutation} from "@apollo/client";
import {GIVE_ONLINE} from "../../../graphql-config/query/giving";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

const TitheModal = ({ event, color }) => {
    const [signupModal, setSignupModal] = useState(false);
    const [email, setEmail] = useState('')
    const [titheNumber, setTitheNumber] = useState('')
    const [amount, setAmount] = useState('')
    const [status, setStatus] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const [giveOnline] = useMutation(GIVE_ONLINE);

    const handleEmailChange = e => {
        if (email !== '') {
            setErrorMessage('')
        }
        setEmail(e.target.value)
    }

    const handleAmountChange = e => {
        if (amount > 0) {
            setErrorMessage('')
        }
        setAmount(e.target.value)
    }

    const handleTitheNumberChange = e => {
        setTitheNumber(e.target.value)
    }


    const saveGiving = orderId => {
        setStatus('loading');
        let titheData = {
            giverEmail: email,
            amountGiven: amount,
            modeOfGiving: "Paypal",
            eventId: event ? event.id : 0,
            currency: "USD",
            description: "Tithe",
            titheNumber: titheNumber === '' ? 'N-A' : titheNumber,
            paypalId: orderId
        }

        giveOnline(
            {
                variables: {
                    request: titheData
                },
                onCompleted() {
                    setStatus("given")
                },
                onError() {
                    setStatus("failed")
                }
            }
        )
    };

    const giveAgain = () => {
        setStatus('');
        setAmount(0);
    }

    const validate = () => {
        if (!isValidEmail(email)) {
            setErrorMessage("we'll need a valid email")
            return
        }
        if (amount <= 0) {
            setErrorMessage("Amount should be greater than 0")
        }
    }

    const classes = useStyles();
    return (
        <div>
            <HeaderMargin marginWithUnit={'1rem'}/>
            <Button color={color ? color : "info"} onClick={() => setSignupModal(true)}>
                pay tithe
            </Button>
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalSignup
                }}
                open={signupModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setSignupModal(false)}
                aria-labelledby="signup-modal-slide-title"
                aria-describedby="signup-modal-slide-description"
            >
                <Card plain className={classes.modalSignupCard}>
                    <DialogTitle
                        id="signup-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            onClick={() => setSignupModal(false)}
                        >
                            {" "}
                            <Close className={classes.modalClose}/>
                        </Button>
                        <h5 className={classes.cardTitle + " " + classes.modalTitle}>
                            Give
                        </h5>
                    </DialogTitle>
                    <DialogContent
                        id="signup-modal-slide-description"
                        className={classes.modalBody}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                                <InfoArea
                                    className={classes.infoArea}
                                    title="Secure"
                                    description={
                                        <p>
                                            Giving by credit card is powered by paypal platform.
                                            Paypal is safe, secure and trusted by several thousand reputable
                                            companies across the world.
                                            <br/>
                                            <a href={'https://paypal.com'} target={'_blank'}
                                               rel="noopener noreferrer">
                                                Read more about paypal</a>
                                        </p>
                                    }
                                    icon={Secure}
                                    iconColor="primary"
                                />
                                <InfoArea
                                    className={classes.infoArea}
                                    title="Available Data"
                                    description={
                                        <p>
                                            Giving by credit card gives you an opportunity to track your giving.
                                            You'll get a receipt from us and you'll always be able to request
                                            information on all your givings.
                                        </p>
                                    }
                                    icon={Data}
                                    iconColor="primary"
                                />
                            </GridItem>
                            {
                                status === 'given' ?
                                    <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
                                        <HeaderMargin marginWithUnit={'6rem'}/>
                                        <form className={classes.form}>
                                            <div className={classes.textCenter}>
                                                <h5>
                                                    Thank you so much for paying your tithe.
                                                    <br/>
                                                    We are so grateful.
                                                    <br/>
                                                    We've sent you an email with a receipt.
                                                </h5>
                                                <Button color="primary" onClick={giveAgain}>
                                                    give again
                                                </Button>
                                            </div>
                                        </form>
                                    </GridItem>
                                    :
                                    status === 'loading' ?
                                        <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
                                            <HeaderMargin marginWithUnit={'8rem'}/>
                                            <Card profile plain>
                                                <CardBody>
                                                    <CustomLinearProgress
                                                        variant="indeterminate"
                                                        color="primary"
                                                        value={60}
                                                    />
                                                    <h4 className={'title'}>one moment please...</h4>
                                                </CardBody>
                                            </Card>
                                        </GridItem>
                                        :
                                        <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
                                            <HeaderMargin marginWithUnit={'6rem'}/>
                                            <form className={classes.form}>
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Email",
                                                        type: 'email',
                                                        value: email,
                                                        onChange: handleEmailChange
                                                    }}
                                                />
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Tithe number",
                                                        value: titheNumber,
                                                        onChange: handleTitheNumberChange
                                                    }}
                                                />
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Amount in €",
                                                        type: "number",
                                                        value: amount,
                                                        onChange: handleAmountChange
                                                    }}
                                                />

                                                {
                                                    isValidEmail(email) && amount > 0 ?
                                                        <div className={classes.textCenter}>
                                                            <Payment setStatus={setStatus} saveGiving={saveGiving} amount={amount}/>
                                                        </div>
                                                        :
                                                        <div className={classes.textCenter}>
                                                            <Button color="primary" onClick={validate}>
                                                                give
                                                            </Button>
                                                        </div>
                                                }
                                            </form>
                                            <div className={classes.textCenter}>
                                                <Danger>
                                                    {errorMessage}
                                                </Danger>
                                            </div>
                                        </GridItem>
                            }

                        </GridContainer>
                    </DialogContent>
                </Card>
            </Dialog>
        </div>
    );
}

export default TitheModal;
