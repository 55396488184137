import React from "react";
import CustomInput from "../../CustomInput/CustomInput";

const RegisterInput = ({ classes, value, onChange, placeHolder, type, error }) =>(
    <CustomInput
        formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
        }}
        inputProps={{
            placeholder: placeHolder,
            value: value,
            onChange: onChange,
            type: type ? type : 'text',
            error: error
        }}
    />
)

export default RegisterInput
