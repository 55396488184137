import React from "react";
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem";
import CustomInput from "../../../CustomInput/CustomInput";
import CardBody from "../../../Card/CardBody";

const FormBody = ({ emailError, messageError, firstName, handleFirstNameChange,
                      lastName, handleLastNameChange, email, handleEmailChange,
                      message, handleMessageChange}) => (
    <CardBody>
        <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                    labelText="First Name"
                    id="first"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        value: firstName,
                        onChange: handleFirstNameChange
                    }}
                />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                    labelText="Last Name"
                    id="last"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        value: lastName,
                        onChange: handleLastNameChange
                    }}
                />
            </GridItem>
        </GridContainer>
        <CustomInput
            labelText="Email Address"
            id="email-address"
            formControlProps={{
                fullWidth: true,
            }}
            inputProps={{
                value: email,
                onChange: handleEmailChange,
                error: emailError !== ''
            }}
        />
        <CustomInput
            labelText="Your Message"
            id="message"
            formControlProps={{
                fullWidth: true
            }}
            inputProps={{
                multiline: true,
                rows: 5,
                value: message,
                onChange: handleMessageChange,
                error: messageError !== ''
            }}
        />
    </CardBody>
)

export default FormBody;
