import React from "react";
import NavPills from "../../NavPills/NavPills";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import OfferingModal from "./offering-modal";
import Logo from '../../../images/swish/qr.svg'
import SwishLogo from '../../../images/svgs/swish_logo_primary_RGB.svg'
import TitheModal from "./tithe-modal";


const GivingOptions = ({ classes, tithe }) => {
    return (
        <NavPills color={'info'} alignCenter tabs={[
            {
                tabButton: 'Bank Transfer',
                tabContent: (
                    <div>
                        <Card pricing plain>
                            <CardBody pricing plain>
                                <ul>
                                    <li>
                                        <b>Bankgiro</b> 5249-5225
                                    </li>
                                    <li>
                                        <b>Bank</b> Swedbank
                                    </li>
                                    <li>
                                        <b>Acc. No</b> 821499735077845
                                    </li>
                                    <li>
                                        <b>IBAN</b> SE2780000821499735077845
                                    </li>
                                    <li>
                                        <b>Address</b> Södra Hamngatan 27, Göteborg
                                    </li>
                                </ul>

                            </CardBody>
                        </Card>
                    </div>
                )
            },
            {
                tabButton: 'Online',
                tabContent: (
                    <div>
                        <Card pricing plain>
                            <CardBody pricing plain>
                                <ul>
                                    <li>
                                        <b>You can give directly through paypal</b>
                                    </li>
                                    <li>
                                        <b>We accept credit card payments</b>
                                    </li>
                                    <li>
                                        Click the button below to give
                                    </li>

                                </ul>

                                {
                                    tithe ?
                                        <TitheModal color={'primary'}/>
                                        :
                                        <OfferingModal color={'primary'}/>
                                }

                            </CardBody>
                        </Card>
                    </div>
                )
            },
            {
                tabButton: 'Swish',
                tabContent: (
                    <div>
                        <Card pricing plain>
                            <CardBody pricing plain>
                                <h1 className={classes.cardTitle}>
                                    <img src={SwishLogo} alt={'swish'} style={{
                                        width: '40px'
                                    }}/>
                                    {` 123 52 88 436`}
                                </h1>
                                <ul>
                                    <li>
                                        or scan the QR code below from the swish app
                                    </li>
                                </ul>

                                <img src={Logo} alt={'swish'} style={{
                                    width: '15rem'
                                }}/>
                            </CardBody>
                        </Card>
                    </div>
                )
            }
        ]}/>
    )
}

export default GivingOptions;