import React from "react";
import classNames from "classnames";

import GivingOptions from "./giving-options";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle.js";

const useStyles = makeStyles(pricingStyle);

const Offering = () => {
    const classes = useStyles();

    return (
        <div>
            <GridContainer>
                <GridItem
                    md={6}
                    sm={6}
                    className={classNames(
                        classes.mrAuto,
                        classes.mlAuto,
                        classes.textCenter
                    )}
                >
                    <GivingOptions classes={classes}/>
                </GridItem>
            </GridContainer>
        </div>
    )
}
export default Offering;
