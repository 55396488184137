export const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const validatePasswords = (password, passwordRepeat) => {
  return (
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/.test(password) &&
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/.test(passwordRepeat) && (password === passwordRepeat)
  );
};

export const isValidPhoneNumber = (phoneNumber) => {
  return /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(phoneNumber);
};