import React, { useEffect, useState } from "react";
import JCParallax from "../components/rccgjc/parallax/jc-parallax";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import Register from "@material-ui/icons/PersonAdd";
import classNames from "classnames";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Navigate} from "react-router-dom";
import AboutUs from "../components/rccgjc/about-us/about-us";
import WordForTheMonth from "../components/rccgjc/word-for-the-month/word-for-the-month";
import GetToChurch from "../components/rccgjc/get-to-church/get-to-church";
import ContactUs from "../components/rccgjc/contact-us/contact-us";
import {setThemeForHeader } from "../actions/app/appActions";
import {useDispatch} from "react-redux";
import ServicesCard from "../components/rccgjc/services/services-card";
const useStyles = makeStyles(landingPageStyle);

const LandingPage = () => {

    const dispatch = useDispatch();

    useEffect(()=> {
            dispatch(setThemeForHeader('transparent'))
        },
        [])

    const [register, setRegister] = useState(false)
    const classes = useStyles();

    if (register) {
        return <Navigate to={'/register'}/>
    }
    return (
        <div>

            <JCParallax image={require("images/gbg/gbg1.jpg")} filter="dark">
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <h1 className={classes.title}>Welcome to Jesus Connections</h1>
                            <h4>
                                We are so excited that you've logged on to our website.
                                At Jesus Connections, our goal is to provide you with all you to need to grow in your
                                relationship with Jesus regardless of status or background and we've gathered some of
                                the best resources that we believe can help you discover a better way to live.
                            </h4>
                            <br />
                            <Button
                                color="info"
                                size="lg"
                                onClick={() => setRegister(true)}
                            >
                                <Register/>
                                Join Us Online
                            </Button>
                        </GridItem>
                    </GridContainer>
                </div>
            </JCParallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <AboutUs/>
                </div>
                <div>
                    <WordForTheMonth/>
                </div>
                <div style={{
                    marginBottom: '3rem'
                }}>
                    <ServicesCard/>
                </div>

                <div>
                    <GetToChurch/>
                </div>

                <div>
                    <ContactUs/>
                </div>
            </div>
        </div>
    )
}
export default LandingPage;
