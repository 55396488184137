import {
    gql
} from "@apollo/client";

export const VASTRAFFIK_TOKEN = gql`
    query traffic_token {
        token {
            token
        }
    }
`;

export const DIRECTIONS = gql`
    query directions {
        directions {
            id
    }
}
`;

export const STOP_LOCATIONS = gql`
    query getStops($name: String!) {
        stopLocations(name: $name) {
            name
            id
            lat
            lon
            weight
            track
        }
    }
`;