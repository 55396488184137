import React, { useState } from "react";
import {makeStyles} from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";

import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import LandingPageCarousel from "./landing-page-carousel";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

const LandingPageModal = ({ buttonColor, simple, details }) => {
    const [checked, setChecked] = useState(true);
    const classes = useStyles();
    return (
        <div>
            <Button simple={simple} color={ buttonColor ? buttonColor :'transparent'}
                    onClick={() => setChecked(details.status)}>
                show events
            </Button>
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalSignup
                }}
                open={checked}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setChecked(false)}
                aria-labelledby="signup-modal-slide-title"
                aria-describedby="signup-modal-slide-description"
            >
               <LandingPageCarousel details={details.details}/>
            </Dialog>
        </div>
    );
}

export default LandingPageModal;
