import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setThemeForHeader} from "../actions/app/appActions";
import ServicesCard from "../components/rccgjc/services/services-card";
import GetToChurch from "../components/rccgjc/get-to-church/get-to-church";

const ServicesPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setThemeForHeader('white'))
    },
        [])
    return <div>
        <ServicesCard>
            <GetToChurch/>
        </ServicesCard>
    </div>
}
export default ServicesPage;
