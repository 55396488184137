import React from "react";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Muted from "../../Typography/Muted";
import CardFooter from "../../Card/CardFooter";
import Button from "../../CustomButtons/Button";
import GridItem from "../../Grid/GridItem";
import Player from 'images/svgs/music-player.svg'
import moment from "moment";

const Mp3MessageCard = ({ messageTitle,eventName,speaker,date, index, classes, selectMessage  }) => (
    <GridItem xs={12} sm={3} md={3}>

        <Card profile plain>
            <div>
                <img src={Player} style={{
                    width: '16rem',
                    height: '4rem',
                    display: 'inline-block',
                    borderRadius: '100%'
                }} alt={'player'}/>
            </div>
            <CardBody>
                <h4 className={classes.cardTitle}>{messageTitle}</h4>
                <Muted>
                    <h6 className={classes.cardCategory}>{speaker}</h6>
                </Muted>
                <p className={classes.description}>
                    {`${eventName}(${moment(date).format("YY-MM-DD")})`}
                </p>
            </CardBody>
            <CardFooter profile className={classes.justifyContent}>
                <Button color={'transparent'} onClick={() => selectMessage(index)}>
                    <i className='fas fa-play'/>
                    play
                </Button>
            </CardFooter>
        </Card>
    </GridItem>
)
export default Mp3MessageCard;
