import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Schedule from "@material-ui/icons/Schedule";
import Info from "@material-ui/icons/Info";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

const useStyles = makeStyles(styles);

const ServicesCard = ({ children }) => {
    const classes = useStyles();
    React.useEffect(() => {
        if (window) {
            window.addEventListener("resize", addStylesForRotatingCards);
        }
        addStylesForRotatingCards();
        return function cleanup() {
            if (window) {
                window.removeEventListener("resize", addStylesForRotatingCards);
            }
        };
    });
    const addStylesForRotatingCards = () => {
        let rotatingCards = document.getElementsByClassName(classes.cardRotate);
        for (let i = 0; i < rotatingCards.length; i++) {
            let rotatingCard = rotatingCards[i];
            let cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
            let cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
            cardFront.style.height = "unset";
            cardFront.style.width = "unset";
            cardBack.style.height = "unset";
            cardBack.style.width = "unset";
            let rotatingWrapper = rotatingCard.parentElement;
            let cardWidth = rotatingCard.parentElement.offsetWidth;
            let cardHeight = rotatingCard.children[0].children[0].offsetHeight;
            rotatingWrapper.style.height = cardHeight + "px";
            rotatingWrapper.style["margin-bottom"] = 30 + "px";
            cardFront.style.height = "unset";
            cardFront.style.width = "unset";
            cardBack.style.height = "unset";
            cardBack.style.width = "unset";
            cardFront.style.height = cardHeight + 35 + "px";
            cardFront.style.width = cardWidth + "px";
            cardBack.style.height = cardHeight + 35 + "px";
            cardBack.style.width = cardWidth + "px";
        }
    };
    return (
        <div style={{
            marginTop: '4.5rem'
        }}>
            <div className="cd-section" id="cards">
                <div className={classes.sectionWhite}>
                    <div id="morphingCards" className="cd-section">
                        <div className={classes.container}>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={4}>
                                    <div className={classes.rotatingCardContainer}>
                                        <Card color="rose" className={classes.cardRotate}>
                                            <div className={classes.front + " " + classes.wrapperRose}>
                                                <CardBody color className={classes.cardBodyRotate}>
                                                    <h5 className={classes.cardCategorySocialWhite}>
                                                        <Schedule/> 12:00 - 14:30
                                                    </h5>
                                                    <h4 className={classes.cardTitleWhite}>
                                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                                            Sunday Service
                                                        </a>
                                                    </h4>
                                                    <p className={classes.cardDescriptionWhite}>
                                                        Join us every Sunday as we worship together in God's presence.
                                                        Sunday services are streamed on this website, facebook, instagram, twitter
                                                        and periscope.
                                                    </p>
                                                </CardBody>
                                                <CardFooter>
                                                    <div
                                                        className={classes.statsWhite + " " + classes.mlAuto}
                                                    >
                                                        last updated 2024-02-19
                                                    </div>
                                                </CardFooter>
                                            </div>
                                            <div className={classes.back + " " + classes.wrapperRose}>
                                                <CardBody className={classes.cardBodyRotate}>
                                                    <h5 className={classes.cardCategorySocialWhite}>
                                                        <Info/> Information
                                                    </h5>
                                                    <h4 className={classes.cardTitleWhite}>
                                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                                            On every 1st Sunday of the month, we celebrate Thanksgiving Sunday along with all
                                                            RCCG Churches worldwide. We offer thanksgiving for the previous month.
                                                        </a>
                                                    </h4>
                                                </CardBody>
                                            </div>
                                        </Card>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={4}>
                                    <div className={classes.rotatingCardContainer}>
                                        <Card color="rose" className={classes.cardRotate}>
                                            <div className={classes.front + " " + classes.wrapperWarning}>
                                                <CardBody color className={classes.cardBodyRotate}>
                                                    <h5 className={classes.cardCategorySocialWhite}>
                                                        <Schedule/> 19:00 - 20:30
                                                    </h5>
                                                    <h4 className={classes.cardTitleWhite}>
                                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                                            Digging Deep
                                                        </a>
                                                    </h4>
                                                    <p className={classes.cardDescriptionWhite}>
                                                        Join us every Tuesday as we delve into God's word.
                                                        Digging deep services are streamed on this website, facebook, instagram, twitter
                                                        and periscope.
                                                    </p>
                                                </CardBody>
                                                <CardFooter>
                                                    <div
                                                        className={classes.statsWhite + " " + classes.mlAuto}
                                                    >
                                                        last updated 2024-02-19
                                                    </div>
                                                </CardFooter>
                                            </div>
                                            <div className={classes.back + " " + classes.wrapperWarning}>
                                                <CardBody className={classes.cardBodyRotate}>
                                                    <h5 className={classes.cardCategorySocialWhite}>
                                                        <Info/> Information
                                                    </h5>
                                                    <h4 className={classes.cardTitleWhite}>
                                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                                            On the 1st Tuesday of every month, we have a special service called Touch His Garment.
                                                            Here we offer prayers for ourselves and our loved ones
                                                        </a>
                                                    </h4>
                                                </CardBody>
                                            </div>
                                        </Card>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={4}>
                                    <div className={classes.rotatingCardContainer}>
                                        <Card color="rose" className={classes.cardRotate}>
                                            <div className={classes.front + " " + classes.wrapperSuccess}>
                                                <CardBody color className={classes.cardBodyRotate}>
                                                    <h5 className={classes.cardCategorySocialWhite}>
                                                        <Schedule/> 19:00 - 20:30
                                                    </h5>
                                                    <h4 className={classes.cardTitleWhite}>
                                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                                            Holy Ghost Service
                                                        </a>
                                                    </h4>
                                                    <p className={classes.cardDescriptionWhite}>
                                                        Join us every last Friday as we pray, partake of the Holy Communion.
                                                        Holy Ghost services are streamed on this website, facebook, instagram, twitter
                                                        and periscope.
                                                    </p>
                                                </CardBody>
                                                <CardFooter>
                                                    <div
                                                        className={classes.statsWhite + " " + classes.mlAuto}
                                                    >
                                                        last updated 2024-02-19
                                                    </div>
                                                </CardFooter>
                                            </div>
                                            <div className={classes.back + " " + classes.wrapperSuccess}>
                                                <CardBody className={classes.cardBodyRotate}>
                                                    <h5 className={classes.cardCategorySocialWhite}>
                                                        <Info/> Information
                                                    </h5>
                                                    <h4 className={classes.cardTitleWhite}>
                                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                                            On the 1st Friday of every month, we join the RCCG for the worldwide Holy Ghost service
                                                            with Pastor E.A Adeboye as the convener
                                                        </a>
                                                    </h4>
                                                </CardBody>
                                            </div>
                                        </Card>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>

    );
}

export default ServicesCard;
