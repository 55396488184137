import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
    grid: {
        marginRight: "-15px",
        marginLeft: "-15px",
        width: "auto"
    }
};

const useStyles = makeStyles(styles);

const JCGridContainer = (props) => {
    const { children, className, ...rest } = props;
    const classes = useStyles();
    return (
        <Grid container {...rest} className={classes.grid + " " + className}>
            {children}
        </Grid>
    );
}

JCGridContainer.defaultProps = {
    className: ""
};

JCGridContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default JCGridContainer;
