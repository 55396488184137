import React, {Fragment} from "react";
import InfoArea from "../../../InfoArea/InfoArea";
import Timeline from "@material-ui/icons/Favorite";
import Button from "components/CustomButtons/Button.js";
import Success from "../../../Typography/Success";

const NewsletterSuccess = ({ classes, clear }) => (
    <Fragment>
        <InfoArea
            className={classes.infoArea}
            title="Congratulations"
            description={
                <Success>
                    You've been added to our mailing list..
                </Success>
            }
            icon={Timeline}
            iconColor="info"
        />
        <div className={classes.textCenter}>
            <Button color={'info'} onClick={() => clear()}>
                Close
            </Button>
        </div>
    </Fragment>
)
export default NewsletterSuccess;
