import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

import Apps from "@material-ui/icons/Apps";

import Layers from "@material-ui/icons/Layers";
import LineStyle from "@material-ui/icons/LineStyle";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import JCCustomDropdown from "../drop-down/jc-dropdown";

const useStyles = makeStyles(styles);

const JCHeaderLinks = (props) => {
    const { dropdownHoverColor } = props;
    const classes = useStyles();

    return (
        <List className={classes.list + " " + classes.mlAuto}>
            <ListItem className={classes.listItem}>
                <JCCustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={dropdownHoverColor}
                    buttonText="about us"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={Apps}
                    dropdownList={[
                        <Link to="/about-us" className={classes.dropdownLink}>
                            About us
                        </Link>
                    ]}
                />
            </ListItem>
            <ListItem className={classes.listItem}>
                <JCCustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={dropdownHoverColor}
                    buttonText="rccg"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={Apps}
                    dropdownList={[
                        <Link to="/rccg#history" className={classes.dropdownLink}>
                            <LineStyle className={classes.dropdownIcons} /> History
                        </Link>,
                        <Link to="/rccg#origin" className={classes.dropdownLink}>
                            <Layers className={classes.dropdownIcons} />
                            Origin
                        </Link>,
                        <Link
                            to={'/rccg#mission'}
                            className={classes.dropdownLink}
                        >
                            <Icon className={classes.dropdownIcons}>content_paste</Icon>
                            Mission
                        </Link>
                    ]}
                />
            </ListItem>
            <ListItem className={classes.listItem}>
                <JCCustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={dropdownHoverColor}
                    buttonText="services"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={Apps}
                    dropdownList={[
                        <Link to="/services" className={classes.dropdownLink}>
                            Sunday Service
                        </Link>,
                        <Link to="/services" className={classes.dropdownLink}>
                            Digging Deep
                        </Link>,
                        <Link
                            to={'/services'}
                            className={classes.dropdownLink}
                        >
                            Holy Ghost Service
                        </Link>
                    ]}
                />
            </ListItem>
            <ListItem className={classes.listItem}>
                <JCCustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={dropdownHoverColor}
                    buttonText="ministries"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={Apps}
                    dropdownList={[
                        <Link to="/ministries/men" className={classes.dropdownLink}>
                            Men's ministry
                        </Link>,
                        <Link to="/ministries/women" className={classes.dropdownLink}>
                            Women's ministry
                        </Link>,
                        <Link
                            to={'/ministries/music'}
                            className={classes.dropdownLink}
                        >
                            Music ministry
                        </Link>,
                        <Link
                            to={'/ministries/children'}
                            className={classes.dropdownLink}
                        >
                            Children's ministry
                        </Link>,
                        <Link
                            to={'/ministries/prayer'}
                            className={classes.dropdownLink}
                        >
                            Prayer ministry
                        </Link>,
                        <Link
                            to={'/ministries/hospitality'}
                            className={classes.dropdownLink}
                        >
                            Hospitality ministry
                        </Link>
                    ]}
                />
            </ListItem>
            <ListItem className={classes.listItem}>
                <JCCustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={dropdownHoverColor}
                    buttonText="study"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={Apps}
                    dropdownList={[
                        <a href={'https://www.biblegateway.com/'}
                           target={'_blank'}
                           rel="noopener noreferrer"
                           className={classes.dropdownLink}>
                            Bible
                        </a>,
                        <Link to="/study" className={classes.dropdownLink}>
                            New note
                        </Link>,
                        <Link to="/retrievenotefromid" className={classes.dropdownLink}>
                            Retrieve note
                        </Link>
                    ]}
                />
            </ListItem>
            <ListItem className={classes.listItem}>
                <JCCustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={dropdownHoverColor}
                    buttonText="giving"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={Apps}
                    dropdownList={[
                        <Link to="/giving" className={classes.dropdownLink}>
                            Offering
                        </Link>,
                        <Link to="/tithe" className={classes.dropdownLink}>
                            Tithe
                        </Link>
                    ]}
                />
            </ListItem>
            <ListItem className={classes.listItem}>
                <JCCustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={dropdownHoverColor}
                    buttonText="Media"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={Apps}
                    dropdownList={[
                        <Link to="/streaming" className={classes.dropdownLink}>
                            Live Stream
                        </Link>,
                        <Link to="/replay" className={classes.dropdownLink}>
                            Replay
                        </Link>
                    ]}
                />
            </ListItem>
            <ListItem className={classes.listItem}>
                <JCCustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={dropdownHoverColor}
                    buttonText="connect"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={Apps}
                    dropdownList={[
                        <Link to="/register" className={classes.dropdownLink}>
                            Register
                        </Link>,
                        <Link to="/contact-us" className={classes.dropdownLink}>
                            Contact us
                        </Link>
                    ]}
                />
            </ListItem>
        </List>
    );
}

JCHeaderLinks.defaultProps = {
    hoverColor: "primary"
};

JCHeaderLinks.propTypes = {
    dropdownHoverColor: PropTypes.oneOf([
        "dark",
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose"
    ])
};
export default JCHeaderLinks;
