import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";


import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import GO2 from "images/daddyGO/EAAdeboye3.jpg"
import GO from "images/daddyGO/EA Adeboye2.jpg"
import GO3 from "images/daddyGO/EA Adeboye.jpg"
import PA from "images/daddyGO/Akindayomi-Josiah-Olufemi.jpg"

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

import {useDispatch} from "react-redux";
import {setThemeForHeader} from "../actions/app/appActions";

const useStyles = makeStyles(featuresStyle);

const RccgPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(()=> {
            dispatch(setThemeForHeader('white'))
        },
        [])

    return (

        <div className="cd-section">
            <div style={{
                marginTop: '8rem'
            }}/>
            <div className={classes.container}>

                <div className={classes.features3}>
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <div style={{
                                marginTop: '45px'
                            }}>
                                <img src={GO2} alt="..." style={{
                                    width: '90%'
                                }}/>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <h2 className={classes.title}>History</h2>
                            <h4>
                                The Redeemed Christian Church of God (RCCG) has a vision to spread the good news (Word of
                                God) to the ends of the earth.
                                It is a Bible believing church, where the teaching and a missionary methodology accentuate
                                the proper conduct and moral behavior of people.
                                The church has an appeal to all manners of people worldwide, with a purpose and
                                determination to teach people in their various geographical areas, stages of secular life
                                and levels of spiritual development.
                                The General Overseer of the church is Pastor E.A. Adeboye.
                                RCCG was founded in 1952 and since then, the church has grown to over 23,000 parishes
                                worldwide (July 2011).
                                At the moment, there are various established missions in many African countries, as well as
                                Europe, Asia, the United States, the Caribbean, Canada, South America and Australia.
                                RCCG has also been involved in establishing schools, rehabilitation centers, child care and
                                support centers.
                            </h4>
                        </GridItem>
                    </GridContainer>
                </div>

                <div className={classes.features3}>
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <div style={{
                                marginTop: '150px'
                            }}>
                                <img src={PA} alt="..." style={{
                                    width: '100%'
                                }}/>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <h2 className={classes.title}>Origin</h2>
                            <h4>
                                The Lord then established a covenant with Pa Akindayomi, synonymous to the Abrahamic covenant in
                                the Bible. He said that He the Lord would meet all the needs of the church in an awesome way if
                                only members would serve Him faithfully and be obedient to His Word. It is upon this covenant
                                that the Redeemed Christian Church of God was built.

                                Thus, the Redeemed Christian Church of God was born in 1952, destined by the Lord Himself to
                                take the world for Him. The church continued to meet at 9 Willoughby Street until they were able
                                to acquire some land thereby witnessing relocation to the present site of the Headquarters of
                                the church at 1-5 Redemption Way, Ebute-Metta, Lagos (formerly 1a, Cemetery Street).

                                Sometime in the early 70s, God had spoken to Pa Akindayomi about his successor. The Lord told
                                him that this man, who was not a member of the church then, would be a young educated man. Thus
                                when a young university lecturer joined the church in 1973, Papa was able to recognize him in
                                the Spirit as the one that the Lord had spoken about in the past. This man, Enoch Adejare
                                Adeboye who was then a lecturer of Mathematics at the University of Lagos soon became involved
                                in the church. He became one of the interpreters translating Pa Akindayomi’s sermons from Yoruba
                                to English. He was ordained a pastor of the church in 1975.
                            </h4>
                        </GridItem>
                    </GridContainer>
                </div>
                <article>
                    <h4>
                        He asked for signs to confirm that this was indeed God’s call. The confirmation came through the
                        Bible passages of Jeremiah 1:4-10, Isaiah 41:10-13 and Romans 8:29-31. The Lord assured him that
                        He would provide for all his needs, as he would not receive any salary from that point on. This
                        proved to be a comforting reminder during the trials in the months ahead. He became married in
                        1941. He continued to worship with the Cherubim and Seraphim. In 1947, he started to become
                        concerned that the church was departing from the true Word of God in some of its practices. By
                        1952, he felt totally persuaded to leave the church. He started at Willoughby Street,
                        Ebute-Metta, Lagos a house fellowship called, the Glory of God Fellowship. Initially there were
                        nine members but before long the fellowship rapidly grew as the news of the miracles that
                        occurred in their midst spread.

                        Pa Akindayomi also had a vision of words that appeared to be written on a blackboard. The words
                        were “The Redeemed Christian Church of God.” Amazingly, Pa Akindayomi who could not read or
                        write was supernaturally able to write these words down. In this visitation, God also said to
                        him that this church would go to the ends of the earth and that when the Lord Jesus Christ
                        appeared in glory, He would meet the church.
                    </h4>

                   <h4>
                       The Lord then established a covenant with Pa Akindayomi, synonymous to the Abrahamic covenant in
                       the Bible. He said that He the Lord would meet all the needs of the church in an awesome way if
                       only members would serve Him faithfully and be obedient to His Word. It is upon this covenant
                       that the Redeemed Christian Church of God was built.

                       Thus, the Redeemed Christian Church of God was born in 1952, destined by the Lord Himself to
                       take the world for Him. The church continued to meet at 9 Willoughby Street until they were able
                       to acquire some land thereby witnessing relocation to the present site of the Headquarters of
                       the church at 1-5 Redemption Way, Ebute-Metta, Lagos (formerly 1a, Cemetery Street).

                       Sometime in the early 70s, God had spoken to Pa Akindayomi about his successor. The Lord told
                       him that this man, who was not a member of the church then, would be a young educated man. Thus
                       when a young university lecturer joined the church in 1973, Papa was able to recognize him in
                       the Spirit as the one that the Lord had spoken about in the past. This man, Enoch Adejare
                       Adeboye who was then a lecturer of Mathematics at the University of Lagos soon became involved
                       in the church. He became one of the interpreters translating Pa Akindayomi’s sermons from Yoruba
                       to English. He was ordained a pastor of the church in 1975.
                   </h4>
                </article>

                <div className={classes.features3}>
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <div style={{
                                marginTop: '20px'
                            }}>
                                <img src={GO} alt="..." style={{
                                    width: '100%'
                                }}/>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <h4>
                                Papa was preparing to meet his Creator. He sent for Pastor Adeboye and spent several hours
                                sharing with him details of the covenant and the plans of the Lord for the church. Even
                                though a year before this, the Lord had revealed to Pastor Adeboye that he would be Papa’s
                                successor, it was still too difficult for him to fully contemplate such an awesome
                                responsibility.

                                Pa Josiah Akindayomi was 71 years old when he died. Amidst controversy, Pastor Adeboye’s
                                appointment was formalized by the reading of Pa Akindayomi’s sealed pronouncement after his
                                burial.

                                Since 1981, an open explosion began with the number of parishes growing in leaps and bounds.
                                At the last count, there are at least about 2000 parishes of the Redeemed Christian Church
                                of God in Nigeria. On the International scene, the church is present in other African
                                nations including C’ote D’Ivoire, Ghana, Zambia, Malawi, Zaire, Tanzania, Kenya, Uganda,
                                Gambia, Cameroon, and South Africa. In Europe , the church is fully established in the
                                following countries: United Kingdom, Netherlands, Spain, Italy, Germany, Greece, France,
                                Switzerland, Austria, Denmark, Sweden and Norway.
                            </h4>
                        </GridItem>
                    </GridContainer>
                </div>
                <article>
                    <h4>
                        In North America there are about 600 parishes (July 2011) spread in various Cities and States of
                        USA and Canada. Also there are parishes in the Caribbean states of Haiti and Jamaica. There are
                        parishes in South America, the Middle East, Australia, etc

                        Today, God is still doing marvelous deeds through The Redeemed Christian Church of God,
                        worldwide. One of the well-known programs of the church is the Holy Ghost Service, an all night
                        miracle service that holds on the first Friday of every month at the Redemption Camp at Km. 46,
                        Lagos – Ibadan expressway. The average headcount of those who attend the Service is in millions.
                        The Holy Ghost Service now holds all around the worldmonthly but is led by Pastor Adeboye,
                        half-yearly in London. Similarly, Pastor Adeboye leads these services in Washington DC and
                        Toronto, Ontario, Canada once in a year and once in Europe every two years.
                    </h4>
                </article>

                <div className={classes.features3}>
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <div style={{
                                marginTop: '20px'
                            }}>
                                <img src={GO3} alt="..." style={{
                                    width: '100%'
                                }}/>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <h4>
                                As received by the General Overseer (G.O.), Pastor E. A. Adeboye, and communicated to the
                                Headquarters leaders, our vision and mission statement shall remain intact. They are as
                                follows:

                                <ol>
                                    <li>
                                        To make heaven
                                    </li>
                                    <li>
                                        To take as many people with us
                                    </li>
                                    <li>
                                        To have a member of RCCG in every family of all nations
                                    </li>
                                    <li>
                                        To accomplish No. 1 above, holiness will be our lifestyle
                                    </li>
                                    <li>
                                        To accomplish No. 2 and 3 above, we will plant churches within five minutes walking distance
                                        in every city and town of developing countries and within five minutes driving distance in
                                        every city and town of developed countries.
                                    </li>
                                </ol>
                                We will pursue these objectives until every Nation in the world is reached for the Lord
                                Jesus Christ.
                            </h4>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}

export default RccgPage;
