import {
    gql
} from "@apollo/client";

export const REGISTER_USER = gql`
mutation registerUser($request: UserRegisterRequest!){
  registerUser(input: $request) {
    email
    firstName
    lastName
  }
}
`;

export const REGISTER_NEWSLETTER_USER = gql`
    mutation registerNewsletterUser($email: String!) {
        registerNewsletterUser(input:$email)
    }
`;
