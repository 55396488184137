const initMap = () => {
    let m = new Map();
    m.set("men",0);
    m.set("women", 1);
    m.set("music", 2);
    m.set("children", 3);
    m.set("prayer", 4);
    m.set("hospitality", 5);
    return m;
}

export const ministryMap = initMap();

