import React from "react";
const LegDetails = ({originName, destinationName, color, bgColor, index, destTrack}) => (
    <div>
        <p
           style={{ color: color === "#ffffff" || color === '#D0EDFB' || color === '#D8E8B0' || color === '#FFE852' || color === '#FDCC99' ? bgColor : color }}
           key={index}>
            {originName.replace("Spårvagn", "Tram ").replace("Buss", "Bus ").replace("Gå", "Walk ").split(",")[0]}
            <span
                className='dark-gray'> to {originName.indexOf("Gå") >= 0 && destTrack !== undefined ? "platform " + destTrack : destinationName.split(",")[0]}</span>
        </p>
    </div>
)

export default LegDetails;
