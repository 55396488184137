import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";

import { isValidEmail } from "../validations";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import image from "images/gbg/gbg1.jpg";

import HeaderMargin from "../components/rccgjc/header-margin/header-margin";
import {useDispatch} from "react-redux";
import {setThemeForHeader} from "../actions/app/appActions";
import RegisterInput from "../components/rccgjc/register/input";
import CustomInput from "../components/CustomInput/CustomInput";
import Button from 'components/CustomButtons/Button'
import JCToolTip from "../components/rccgjc/tooltip/jc-tool-tip";

import {
    useSearchParams
} from "react-router-dom";
import Danger from "../components/Typography/Danger";
import CustomLinearProgress from "../components/CustomLinearProgress/CustomLinearProgress";
import Info from "../components/Typography/Info";
import {useLazyQuery} from "@apollo/client";
import {RETRIEVE_NOTE} from "../graphql-config/query/study-note";

const useStyles = makeStyles(signupPageStyle);



const RetrieveNotePage = () => {

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [notes, setNotes] = useState('');
    const [noteTitle, setNoteTitle] = useState('');
    const [status, setStatus] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [notesError, setNotesError] = useState(false)
    const [noteId, setNoteId] = useState(null)
    const [search, setSearch] = useState('')
    const [showSearch, setShowSearch] = useState(false)
    const [saveStatus, setSaveStatus] = useState('')

    let [searchParams, setSearchParams] = useSearchParams();

    let [loadNote, { data, error, loading }] = useLazyQuery(RETRIEVE_NOTE);

    useEffect(() => {
        if (data) {
            const { studyNoteId, notes, noteTitle, email, firstName } = data.retrieveNote;
            setStatus('done')
            setEmail(email)
            setNoteTitle(noteTitle)
            setNotes(notes)
            setFirstName(firstName)
            setNoteId(studyNoteId)
        }
    }, [data]);

    useEffect(() => {
        if (loading) {
            setStatus("loading");
        }
    }, [loading]);

    useEffect(() => {
        if (error) {
            setStatus('failed');
            setShowSearch(true)
            setSearch(noteId || '')
        }
    }, [error]);

    const retrieveNote = id => {
        loadNote({
            variables: {
                noteId: id
            }
        })
    };

    useEffect(()=>{
        let id = searchParams.get("id");

        if (id) {
            setNoteId(id)
            retrieveNote(id)
        }else {
            setStatus('failed')
        }
    },[searchParams])

    const dispatch = useDispatch();
    useEffect(()=> {
            dispatch(setThemeForHeader('white'))
        },
        [])

    const handleOnSearchChange = e => {
        if (search !== '') {
            setShowSearch(false)
        }
        setSearch(e.target.value)
    }

    const handleEmailChange = e => {
        if (emailError){
            setEmailError(false)
        }
        setEmail(e.target.value)
    }

    const handleFirstNameChange = e => {
        setFirstName(e.target.value)
    }

    const handleNotesChange = e => {
        setNotes(e.target.value)
    }

    const handleNoteTitleChange = e => {
        if (notesError){
            setNotesError(false)
        }
        setNoteTitle(e.target.value)
    }

    const save = () => {
        if (!isValidEmail(email)){
            setEmailError(true)
            return
        }

        if (notes.length < 10) {
            setNotesError(true)
            return
        }

        const cookies = document.cookie.split(";");
        const token = cookies.find(el => el.toLowerCase().includes("xsrf")).split("=")[1]

        let studyContext = {
            email: email,
            firstName: firstName === "" ? null : firstName,
            lastName: null,
            noteTitle: noteTitle === "" ? null : noteTitle,
            notes: notes,
            bibleVerse: null,
            verses: null,
            bibleState: null,
            noteId: noteId
        }

        setSaveStatus('saving');
        fetch("/web/internal/studynotes/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-XSRF-TOKEN": token
            },
            body: JSON.stringify(studyContext)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw Error(response.statusText);
                }
            })
            .then(resp => {
                setSaveStatus('saved')
                setNoteId(resp.studyNoteId)
                setTimeout(()=> {
                    setSaveStatus('')
                }, 3000)
            })
            .catch(() => {
                setSaveStatus('failed')
            });
    }

    const send = () => {
        if (!isValidEmail(email)){
            setEmailError(true)
            return
        }

        if (notes.length < 10) {
            setNotesError(true)
            return
        }

        const cookies = document.cookie.split(";");
        const token = cookies.find(el => el.toLowerCase().includes("xsrf")).split("=")[1]

        let studyContext = {
            email: email,
            firstName: firstName === "" ? null : firstName,
            lastName: null,
            noteTitle: noteTitle === "" ? null : noteTitle,
            notes: notes,
            bibleVerse: null,
            verses: null,
            bibleState: null,
            noteId: noteId
        }

        setSaveStatus('sending');
        fetch("/web/internal/studynotes/sendnote", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-XSRF-TOKEN": token
            },
            body: JSON.stringify(studyContext)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw Error(response.statusText);
                }
            })
            .then(resp => {
                setSaveStatus('sent')
                setNoteId(resp.studyNoteId)
                setTimeout(()=> {
                    setSaveStatus('')
                }, 3000)
            })
            .catch(() => {
                setSaveStatus('failed')
            });
    }
    const classes = useStyles();
    return (
        <div>
            <HeaderMargin marginWithUnit={'2rem'}/>
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.container}>
                    <GridContainer justifyContent="center">
                        <GridItem xs={12} sm={10} md={10}>
                            <Card className={classes.cardSignup}>
                                <h2 className={classes.cardTitle}>{`Study Note`}</h2>
                                {
                                    status === 'done' ?
                                        <h5 className={classes.textCenter}>{noteId}</h5>
                                        :
                                        null
                                }
                                <CardBody>
                                    <GridContainer justifyContent="center">
                                        {
                                            status === 'done' ?
                                                <GridItem xs={12} sm={5} md={5}>
                                                    {
                                                        saveStatus === 'saving' ?
                                                            <small>one moment please...</small>
                                                            :
                                                            saveStatus === 'saved' ?
                                                                <small>saved</small>
                                                                :
                                                                saveStatus === 'sending' ?
                                                                    <small>sending</small>
                                                                    :
                                                                    saveStatus === 'sent' ?
                                                                        <small>sent</small>
                                                                        :
                                                                        null
                                                    }
                                                    <form className={classes.form}>
                                                        <RegisterInput classes={classes}
                                                                       onChange={handleEmailChange}
                                                                       value={email} placeHolder={'your@email.com'} error={emailError}
                                                                       type={'email'}/>

                                                        <RegisterInput classes={classes}
                                                                       onChange={handleFirstNameChange}
                                                                       value={firstName} placeHolder={'your name'}/>

                                                        <RegisterInput classes={classes}
                                                                       onChange={handleNoteTitleChange}
                                                                       value={noteTitle} placeHolder={'a title for your note.'}/>
                                                        <CustomInput
                                                            labelText="Your study notes"
                                                            id="message"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                multiline: true,
                                                                rows: 10,
                                                                value: notes,
                                                                onChange: handleNotesChange,
                                                                error: notesError
                                                            }}
                                                        />
                                                    </form>
                                                    <div className={classes.textCenter}>
                                                        <JCToolTip message={'Save a copy of your message and return to it any time'}>
                                                            <Button color={'info'} onClick={save} disabled={status === 'loading'}>
                                                                Save
                                                            </Button>
                                                        </JCToolTip>
                                                        <JCToolTip message={'Send this message to yourself or to any of your friends'}>
                                                            <Button color={'rose'} disabled={status === 'loading' || noteId === null} onClick={send}>
                                                                Send
                                                            </Button>
                                                        </JCToolTip>
                                                    </div>
                                                </GridItem>
                                                :
                                                status === 'failed' ?
                                                <GridItem xs={12} sm={5} md={5}>
                                                    <form className={classes.form}>
                                                        <RegisterInput
                                                            classes={classes}
                                                            onChange={handleOnSearchChange}
                                                            value={search} placeHolder={'your note id'}/>
                                                            <div className={classes.textCenter}>
                                                                <JCToolTip message={'Click to load your note using the id you provided'}>
                                                                    <Button color={'info'} onClick={()=> retrieveNote(search)} disabled={search === ''}>
                                                                        Search
                                                                    </Button>
                                                                </JCToolTip>
                                                            </div>
                                                        {
                                                            showSearch ?
                                                                <div className={classes.textCenter}>
                                                                    <Danger>
                                                                        could not find any note with the id above.
                                                                    </Danger>
                                                                </div>
                                                                : null
                                                        }

                                                    </form>
                                                </GridItem>
                                                    :
                                                    <GridItem xs={12} sm={5} md={5}>
                                                        <div className={classes.form}>
                                                            <CustomLinearProgress
                                                                variant="indeterminate"
                                                                color="info"
                                                                value={60}
                                                            />
                                                            <div className={classes.textCenter}>
                                                                <Info>one moment please</Info>
                                                            </div>

                                                        </div>
                                                    </GridItem>
                                        }

                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    )
}
export default RetrieveNotePage;
