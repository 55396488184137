import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";

import Close from "@material-ui/icons/Close";
import Face from "@material-ui/icons/LocationOn";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import style from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import BusStopList from "../bustop-list";
import BusStop from "../bus-stop";
import ModalLoading from "../../loading/modal-loading";
import TripView from "../trip-view";
import NoTripFound from "../no-trip-found";
import {
    useLazyQuery,
    useQuery
} from '@apollo/client'
import {
    DIRECTIONS,
    STOP_LOCATIONS,
    VASTRAFFIK_TOKEN
} from "../../../../graphql-config/query/vastraffik";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

const GetToChurchModal = () => {
    const [loadStopLocations, {data}] = useLazyQuery(STOP_LOCATIONS, {
        fetchPolicy: "network-only"
    });

    const tokenData = useQuery(VASTRAFFIK_TOKEN);
    const churchDirections = useQuery(DIRECTIONS);

    const [signupModal, setSignupModal] = useState(false);
    const [token, setToken] = useState('')
    const [locationList, setLocationList] = useState([])
    const [church, setChurch] = useState('')
    const [currentLocation, setCurrentLocation] = useState('')
    const [selectedStop, setSelectedStop] = useState({})
    const [status, setStatus] = useState('')
    const [trip, setTrip] = useState(null)
    const [selected, setSelected] = useState(false)


    useEffect( () => {
        if (data) {
            let stopLocation = data.stopLocations;
            let sortedStops = sortStops(stopLocation);
            setLocationList(sortedStops);
        }
    }, [data]);

    useEffect(() => {
        if (tokenData.data) {
            setToken(tokenData.data.token.token);
        }
    }, [tokenData]);

    useEffect(() => {
        if (churchDirections.data) {
            const { id } = churchDirections.data.directions;
            setChurch(id);
        }
    }, [churchDirections])

    const selectStop = (key) => {
        const found = locationList.filter(stop => {
            return (
                stop.id === key
            );
        }).shift();
        setSelectedStop({
            name: found.name,
            track: found.track,
            id: found.id,
            lat: found.lat,
            lon: found.lon,
            weight: found.weight
        });
        setSelected(true)
        getTrip(found, church);
    };

    const unSelectStop = () => {
        setSelected(false)
        setSelectedStop({})
    }


    const getTrip = (found,destination) => {
        tokenData.refetch();
        setStatus("loading");
        fetch("https://api.vasttrafik.se/bin/rest.exe/v2/trip?originId=" + found.id + "&destId=" + destination + "&format=json", {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw Error(response.statusText);
                }
            })
            .then(resp => {
                if (resp.TripList.error) {
                    setStatus("noTripFound")
                } else {
                    setTrip(resp)
                    setStatus("success")
                }
            })
            .catch(() => {
                setStatus("failed")
            });
    }

    const sortStops = (list) => {
        let mySet = new Set();
        let newArray = [];
        for (let i = 0; i < list.length; i++) {
            if (!mySet.has(list[i].name)) {
                newArray = newArray.concat(list[i]);
                mySet.add(list[i].name);
            }
        }
        return newArray;
    };

    const handleCurrentLocation = event => {
        setCurrentLocation(event.target.value)
    }

    const searchedStops = locationList.filter(stop => {
        return (stop.name.toLowerCase().includes(currentLocation.toLowerCase()))
    });

    const loadStops = search => {
        loadStopLocations({
            variables: {
                name: search
            }
        })
    }

    useEffect(() => {
        if (currentLocation.length >= 4) {
            loadStops(currentLocation)
        }
    }, [currentLocation])

    const classes = useStyles();
    return (
        <div>
            <Button color='info' onClick={() => setSignupModal(true)}>
                try it now
            </Button>
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalSignup
                }}
                open={signupModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setSignupModal(false)}
                aria-labelledby="signup-modal-slide-title"
                aria-describedby="signup-modal-slide-description"
            >
                <Card plain className={classes.modalSignupCard}>
                    <DialogTitle
                        id="signup-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            onClick={() => setSignupModal(false)}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        <h5 className={classes.cardTitle + " " + classes.modalTitle}>
                            Travel Planner
                        </h5>
                    </DialogTitle>
                    <DialogContent
                        id="signup-modal-slide-description"
                        className={classes.modalBody}
                    >
                        <GridContainer justifyContent={'center'}>
                            {
                                !selected ?
                                    <GridItem xs={12} sm={5} md={5}>
                                        <form className={classes.form}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <Face className={classes.inputAdornmentIcon} />
                                                        </InputAdornment>
                                                    ),
                                                    value: currentLocation,
                                                    onChange: handleCurrentLocation,
                                                    placeholder: "Enter your nearest stop..."
                                                }}
                                            />
                                        </form>
                                        {
                                            currentLocation.length >= 4 ?
                                                <BusStopList
                                                    stops={searchedStops}
                                                    selectStop={selectStop}
                                                    unSelectStop={unSelectStop}/>
                                                    :
                                                null
                                        }
                                    </GridItem>
                                    :
                                    null
                            }

                            {
                                selected ?
                                <GridItem xs={12} sm={5} md={5}>
                                    <div className={classes.textCenter}>
                                        <BusStop unSelectStop={unSelectStop}
                                                 selectStop={selectStop}
                                                 track={selectedStop.track}
                                                 weight={selectedStop.weight}
                                                 id={selectedStop.id}
                                                 name={selectedStop.name}
                                                 lat={selectedStop.lat}
                                                 lon={selectedStop.lon}
                                                 simple
                                                 selected={selected}
                                                 color={'info'}/>
                                    </div>

                                    {
                                        status === 'loading' ?
                                            <ModalLoading classes={classes} close={setSignupModal}/>
                                            :
                                        status === 'success' && selected ?
                                            <TripView trip={trip.TripList.Trip}/>
                                            :
                                            status === "noTripFound" && selected ?
                                                <NoTripFound
                                                    classes={classes}
                                                    message={`No connections found from your location .\nContact ${selectedStop.name} for proper travel information`}
                                                    close={setSignupModal}/>
                                                :
                                                null
                                    }
                                </GridItem>
                                    :
                                    null
                            }

                        </GridContainer>
                    </DialogContent>
                </Card>
            </Dialog>
        </div>
    );
}

export default GetToChurchModal;
