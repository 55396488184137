import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
    grid: {
        position: "relative",
        width: "100%",
        minHeight: "1px",
        paddingRight: "15px",
        paddingLeft: "15px"
    }
};

const useStyles = makeStyles(styles);

const JCGridItem = (props) => {
    const { children, className, ...rest } = props;
    const classes = useStyles();
    return (
        <Grid item {...rest} className={classes.grid + " " + className}>
            {children}
        </Grid>
    );
}

JCGridItem.defaultProps = {
    className: ""
};

JCGridItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default JCGridItem;
