import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";

import giving from "images/giving/clay-banks-tgquMvhNLc4-unsplash.jpg";
import JCGridContainer from "../components/rccgjc/grid/jc-grid-container";
import JCGridItem from "../components/rccgjc/grid/jc-grid-item";
import Offering from "../components/rccgjc/giving/offering";

const useStyles = makeStyles(contactsStyle);

const GivingPage = () => {

    const classes = useStyles();
    return (
        <div className="cd-section">
            <div
                className={classes.contacts + " " + classes.section}
                style={{backgroundImage: `url(${giving})`}}
            >
                <div className={classes.container}>
                    <h2 className={`${classes.title} ${classes.textCenter}`}>Giving</h2>
                    <h3 className={`${classes.description} ${classes.textCenter}`}>
                        Giving is a part of our faith and we encourage ourselves to participate in it as often as we can.
                        John 3:16 tells us that God so loved the world that He gave His only begotten son. Giving is not
                        only a sign of love for God but it is also
                        one of the ways the church is able to carry out many of the humanitarian projects that it is
                        involved in
                    </h3>
                </div>
            </div>
            <JCGridContainer justifyContent={'center'}>
                <JCGridItem>
                    <div className={classes.container}>
                        <div className={classes.textCenter}>
                            <h2 className={classes.title}>Ways to give</h2>
                        </div>
                        <Offering/>
                    </div>
                </JCGridItem>
            </JCGridContainer>
        </div>
    );
}
export default GivingPage;
