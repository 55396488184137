import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import {Link} from "react-router-dom";
import RegisterInput from "./input";
import RegisterCountry from "./country-input";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";


import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import {makeStyles} from "@material-ui/core/styles";
const useStyles  = makeStyles(tooltipsStyle)
const RegisterForm = ({   classes,
                          firstName,
                          handleFirstName,
                          lastName,
                          handleLastName,
                          email,
                          handleEmail,
                          phoneNumber,
                          handlePhoneNumber,
                          address,
                          handleAddress,
                          postCode,
                          handlePostCode,
                          countries,
                          country,
                          selectCountry,
                          handleCountry,
                          handleBirthdate,
                          setChecked,
                          checked,
                          firstNameError,
                          lastNameError,
                          emailError,
                          phoneNumberError, isWorker, setIsWorker, setLibraryOptIn, libraryOptIn,
                          children }) => {

    const tooltips = useStyles();

    return (<form className={classes.form}>
        <RegisterInput
            classes={classes}
            onChange={handleFirstName}
            value={firstName}
            placeHolder={'First Name...'} error={firstNameError}/>

        <RegisterInput
            classes={classes}
            onChange={handleLastName}
            value={lastName}
            placeHolder={'Last Name...'} error={lastNameError}/>

        <RegisterInput
            classes={classes}
            onChange={handleEmail}
            value={email}
            placeHolder={'Email'}
            type={'email'} error={emailError}/>

        <RegisterInput
            classes={classes}
            onChange={handlePhoneNumber}
            value={phoneNumber} error={phoneNumberError}
            placeHolder={'Phone number'}/>

        <RegisterInput
            classes={classes}
            onChange={handleAddress}
            value={address}
            placeHolder={'Address'}/>

        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <div style={{
                marginRight: '10px'
            }}>
                <RegisterInput value={postCode} onChange={handlePostCode} classes={classes} placeHolder={'Post No'}/>
            </div>

            <RegisterCountry countries={countries}
                             country={country}
                             handleCountry={handleCountry}
                             selectCountry={selectCountry}/>
        </div>

        <FormControl fullWidth>
            <Datetime
                timeFormat={false}
                dateFormat={'YYYY-MM-DD'}
                color={'info'}
                inputProps={{placeholder: "Date of birth", required: true}}
                onChange={handleBirthdate}
                closeOnSelect
            />
        </FormControl>

        <div className={classes.textCenter}>
            <Tooltip title={"If you're a worker at Jesus Connections, please select the worker option below. " +
                "By worker, we mean if you belong to 1 or more departments in Jesus Connections"}
                     placement={"top"} classes={{tooltip: tooltips.tooltip}}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isWorker}
                            onChange={event => setIsWorker(event.target.checked)}
                            value="checkedA"
                            classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar
                            }}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="are you a worker?"
                />
            </Tooltip>

        </div>


        <div className={classes.textCenter}>
            <Tooltip title={"We're starting off our new library, please select to join our library's mailing list."}
                     placement={"top"} classes={{tooltip: tooltips.tooltip}}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={libraryOptIn}
                            onChange={event => setLibraryOptIn(event.target.checked)}
                            value="checkedA"
                            classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar
                            }}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="join our library?"
                />
            </Tooltip>

        </div>

        <FormControlLabel
            classes={{
                label: classes.label
            }}
            control={
                <Checkbox

                    onClick={() => setChecked(!checked)}
                    checkedIcon={
                        <Check className={classes.checkedIcon}/>
                    }
                    icon={<Check className={classes.uncheckedIcon}/>}
                    classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                    }}
                    checked={checked}
                />
            }
            label={
                <span>
                              I agree to the{" "}
                    <Link to={'/privacy'} style={{
                        textDecoration: 'none',
                        color: 'grey'
                    }}>terms and conditions</Link>.
                            </span>
            }
        />
        {children}
    </form>)
}

export default RegisterForm;
