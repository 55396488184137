export const INIT = 'init';
export const SET_HEADER_THEME = 'set header theme';
export const SET_LANDING_DETAILS = 'set landing details'

const setHeaderTheme = payload => ({ type: SET_HEADER_THEME, payload: payload })
const setXsrfToken = payload => ({ type: INIT, payload: payload })
const setLandingPageDetails = payload => ({ type: SET_LANDING_DETAILS, payload: payload })

export const setToken = (token) => dispatch => {
    dispatch(setXsrfToken(token))
}

export const setThemeForHeader = payload => dispatch => {
    dispatch(setHeaderTheme(payload))
}

export const setLandingPageDetailsForApp = payload => dispatch => {
    dispatch(setLandingPageDetails(payload))
}

export const decodeToken = () => {
    const cookies = document.cookie.split(";");
    return cookies.find(el => el.toLowerCase().includes("xsrf")).split("=")[1]
}
