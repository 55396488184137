import React from "react";
import JCInput from "../../input/jc-input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import {Link} from "react-router-dom";
import Button from "../../../CustomButtons/Button";

const NewsletterForm = ({ classes, setChecked, checked, email, send, emailChange }) => (
    <div className={classes.form}>
        <JCInput
            formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses
            }}
            inputProps={{
                endAdornment: (
                    <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                    >
                        <Email className={classes.inputAdornmentIcon}/>
                    </InputAdornment>
                ),
                placeholder: "Email...",
                value: email,
                onChange: emailChange
            }}
        />
        <FormControlLabel
            classes={{
                label: classes.label
            }}
            control={
                <Checkbox
                    tabIndex={-1}
                    onClick={() => setChecked(!checked)}
                    checkedIcon={
                        <Check className={classes.checkedIcon}/>
                    }
                    icon={<Check className={classes.uncheckedIcon}/>}
                    classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                    }}
                    checked={checked}
                />
            }
            label={
                <span>
                        I agree to the <Link to='/terms'
                                             style={{
                                                 textDecoration: 'none',
                                                 color: 'grey'
                                             }}>
                                                terms and conditions</Link>
                        .
                      </span>
            }
        />
        <div className={classes.textCenter}>
            <Button  color="info" disabled={!checked && email === ''} onClick={send}>
                subscribe
            </Button>
        </div>
    </div>
);
export default NewsletterForm;
