import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {setThemeForHeader} from "../actions/app/appActions";

import JCAccordion from "../components/Accordion/JCAccordion";
import HeaderMargin from "../components/rccgjc/header-margin/header-margin";

import {
    useParams
} from "react-router-dom";
import {ministryMap} from "../ministryUtil";

const MinistriesPage = () => {
    const { ministry } = useParams();

    const setCode = () => {
        if (ministry) {
            let code = ministryMap.get(ministry);
            if (code) {
                return code
            }
        }
    }

    const [code] = useState(setCode());

    const dispatch = useDispatch();

    useEffect(()=> {
            dispatch(setThemeForHeader('white'))
        },
        [])

    return (

        <div className="cd-section">
            <HeaderMargin/>

            <div style={{
                background: 'white',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '15px'
            }}>
                <div style={{
                    width: '70%'
                }}>
                    <JCAccordion
                        active={code}
                        activeColor={'info'}
                        collapses={[
                            {
                                title: (<h3>Men's Ministry</h3>),
                                content:(<div>
                                    <h4>
                                        The men’s ministry in Jesus Connections is established with a good vision to
                                        reach out to all men, edify and empower them. It is totally committed and
                                        dedicated to helping all men to be the godly men God has called them to be.
                                        Another vision of the men’s ministry is to do everything possible to assist men
                                        to also develop godly character to enable them to influence the world for
                                        Christ. The group exists to organize prayer meetings and co-ordinate activities
                                        such as bowling and picnic for families. These provide opportunities for men to
                                        network. Our purpose is to provide men in the “house” with opportunities and
                                        resources to: The Princes’ mandate is to build men of integrity through regular
                                        meetings where they are able to bond with one another and talk about issues
                                        particular to men. The group is also involved in the Mandate Men’s Ministry and
                                        seeks to encourage men to support the ministry.
                                    </h4>
                                </div>)
                            },
                            {
                                title: (<h3>Women's Ministry</h3>),
                                content:(<div>
                                    <h4>
                                        The men’s ministry in Jesus Connections is established with a good vision to
                                        reach out to all men, edify and empower them. It is totally committed and
                                        dedicated to helping all men to be the godly men God has called them to be.
                                        Another vision of the men’s ministry is to do everything possible to assist men
                                        to also develop godly character to enable them to influence the world for
                                        Christ. The group exists to organize prayer meetings and co-ordinate activities
                                        such as bowling and picnic for families. These provide opportunities for men to
                                        network. Our purpose is to provide men in the “house” with opportunities and
                                        resources to: The Princes’ mandate is to build men of integrity through regular
                                        meetings where they are able to bond with one another and talk about issues
                                        particular to men. The group is also involved in the Mandate Men’s Ministry and
                                        seeks to encourage men to support the ministry.
                                    </h4>
                                </div>)
                            },
                            {
                                title: (<h3>Music Ministry</h3>),
                                content:(<div>
                                    <h4>
                                        They are popularly referred to as Jesus Connections Choir. The department is
                                        responsible for leading the congregation in powerful and scriptural praise and
                                        worship songs into the presence of God. The mission of the choir is to create an
                                        environment for the manifest presence of God by worshipping God in spirit and truth.
                                        It is not unusual for the presence of God to interrupt the service when they are
                                        ministering. Complicating issues surrounding praising and worshiping the lord can
                                        come up, but our choir does more than singing and dancing, they’re the ones that
                                        initiate the spirit of GOD to visit the church! They’re the ones who tell GOD – we
                                        your children have gathered again, be present. Our music department is more than just
                                        music, because the ones in the departments carries vital responsibility and reminds
                                        GOD that we are ready for service and needs his angels! If you have anything in
                                        mind, please do not hesitate to contact us at choir@rccgjesusconnections.org
                                    </h4>
                                </div>)
                            },
                            {
                                title: (<h3>Children's ministry</h3>),
                                content:(<div>
                                    <h4>
                                        The children’s ministry of Jesus Connections is a unique Bible school, designed to
                                        teach children ages zero to ten years. Bible messages through different activities
                                        and experiences to enable them to gain early insight and way to Jesus Christ, His
                                        love and how they can easily respond to that great love. The teachings are aimed at
                                        helping the children to discover to Jesus Christ, learn to pray and many more. The
                                        children are therefore put in a separate class (room) everyday where they are taught
                                        Bible messages in which the concepts are not beyond their ages. They learn and
                                        develop at their own pace. They watch Christian video play with toys, write, draw,
                                        paint pictures, sing, dance, eat etc. Love, peace and joy in Jesus’ Name.
                                    </h4>
                                </div>)
                            },
                            {
                                title: (<h3>Prayer Ministry</h3>),
                                content: (<div>
                                    <h4>
                                        Prayers might have been defined as communicating alternative with GOD, but we at
                                        Jesus Connections find a more advanced definition to prayer. It’s more than a
                                        communicating alternative, it’s also a way for us to keep tribulations, obstacles
                                        out of the way. Though the obstacles might come to you, it doesn’t mean that
                                        the prayers haven’t taken affect. Prayer is the weapon we use to get the
                                        ability to go through anything that comes our way. For these reasons we commonly
                                        refer to our prayer team as "God’s army". The prayer department intercedes on
                                        behalf of the church. They meet regularly for vigils, prayer meetings and
                                        occasionally conduct prayer chains. They pray for Sweden, the world in general,
                                        the church and prayer requests from members and non-members of the church. They
                                        sometimes undertake prayer visits to people’s homes if requested to do so. If you
                                        want you become a member or send your prayer request, please e mail it to the
                                        prayers@rccgjesusconnections.se
                                    </h4>
                                </div>)
                            },
                            {
                                title: (<h3>Hospitality Ministry</h3>),
                                content: (<div>
                                    <h4>
                                        This department is concerned with taking care of guests and members in Jesus Connections.
                                        From hosting guests from both home and abroad, to catering for events held within our church.
                                        Our hospitality department handles all of those.
                                    </h4>
                                </div>)
                            }
                        ]}
                    />
                </div>
            </div>

        </div>
    );
}

export default MinistriesPage;
