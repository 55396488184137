import {INIT, SET_HEADER_THEME, SET_LANDING_DETAILS} from "../../actions/app/appActions";

const defaultState = {
    xsrf_token: '',
    headerTheme: 'transparent',
    landingPageDetails: {
        status: false,
        details: []
    }
}

const appReducer = (state = defaultState, action) => {
    switch (action.type) {
        case INIT:
            return {
                ...state,
                xsrf_token: action.payload
            }

        case SET_HEADER_THEME:
            return {
                ...state,
                headerTheme: action.payload
            }

        case SET_LANDING_DETAILS:
            return {
                ...state,
                landingPageDetails: action.payload
            }

        default:
            return state
    }
}

export default appReducer;
