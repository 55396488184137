import {combineReducers} from "redux";
import appReducer from "./app/appReducer";
import wordForTheMonthReducer from "./wordForTheMonth/wordForTheMonthReducer";
import signupModalReducer from "./signupmodal/signupModalReducer";

const rootReducer = combineReducers({
    rccgjc: appReducer,
    wordForTheMonth: wordForTheMonthReducer,
    signupModal: signupModalReducer
});

export default rootReducer;
