import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/History";
import VerifiedUser from "@material-ui/icons/Timeline";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";
import JCGridContainer from "../grid/jc-grid-container";
import JCGridItem from "../grid/jc-grid-item";
import Button from "components/CustomButtons/Button.js";
import {Link} from "react-router-dom";
const useStyles = makeStyles(productStyle);

const AboutUs = () => {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <JCGridContainer justifyContent="center">
                <JCGridItem xs={12} sm={8} md={8}>
                    <h2 className={classes.title}>About Us</h2>
                    <h5 className={classes.description}>
                        Jesus connections is a dynamic church with a dynamic vision. Our vision is helping you to discover a better way to live.
                        Located in Gothenburg, Sweden, Jesus connections is part of the Redeemed Christian Church of God worldwide.
                        A church with amazing vision for the world.

                        <Button simple color={'info'}>
                            <Link to={'/about-us'} style={{
                                textDecoration: 'none',
                                color: '#00acc1'
                            }}>
                                read more
                            </Link>
                        </Button>
                    </h5>
                </JCGridItem>
            </JCGridContainer>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                        <InfoArea
                            title="Rccg"
                            description="We're part of the Reedemed Christian Church of God which was established in 1952.
                            The General Overseer is Pastor E. A Adeboye with over 6000 churches"
                            icon={Chat}
                            iconColor="info"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <InfoArea
                            title="We're growing"
                            description="Jesus Connections was established in Gothenburg in May 2001 with about four families.
                            We've since grown and have become a large family"
                            icon={VerifiedUser}
                            iconColor="success"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <InfoArea
                            title="Discover your potential"
                            description="At Jesus connections, one of our goals is creating an atmosphere that let's you discover your true
                            identity through the finished work of Christ. We want you to live your best life"
                            icon={Fingerprint}
                            iconColor="danger"
                            vertical
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}

export default AboutUs;

