import React, {Fragment} from "react";
import Mp3MessageCard from "./message-card";

const MessageList = ({ messages, classes, selectMessage }) => (
    <Fragment>
        {messages.map((message, i) =>{
            return <Mp3MessageCard
                date={message.date}
                eventName={message.eventName}
                messageTitle={message.messageTitle}
                speaker={message.speaker}
                classes={classes}
                selectMessage={selectMessage}
                key={i}
                index={i} />
        })}
    </Fragment>
)

export default MessageList;
