import React, {useEffect} from "react";

import {useDispatch} from "react-redux";
import {setThemeForHeader} from "../actions/app/appActions";

import Badge from "../components/Badge/Badge";
import JCAccordion from "../components/Accordion/JCAccordion";
import HeaderMargin from "../components/rccgjc/header-margin/header-margin";


const AboutUsPage = () => {
    const dispatch = useDispatch();

    useEffect(()=> {
            dispatch(setThemeForHeader('white'))
        },
        [])

    return (

        <div className="cd-section">
            <HeaderMargin/>

            <div style={{
                background: 'white',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '15px'
            }}>
                <div style={{
                    width: '70%'
                }}>
                    <JCAccordion
                        active={0}
                        activeColor={'info'}
                        collapses={[
                            {
                                title: (<h3>Our Story</h3>),
                                content:(<div>
                                    <h4>
                                        Jesus Connections is part of the great commission of the Redeemed Christian Church of God
                                        (RCCG). RCCG is a worldwide church that was established in 1952. The General Overseer is
                                        Pastor E. A Adeboye with over 6000 churches. He is the pioneer of the remarkable Holy Ghost
                                        Night monthly service at Redemption Camp in Nigeria which still remains the largest
                                        Christian gathering on the planet today. He is also the host of London Holy Ghost Festival
                                        of Life conference that has been acknowledged the biggest all night prayer meeting in
                                        Europe.

                                        Jesus Connections was established in Gothenburg in May 2001 with about four families. Their
                                        first service was held at Medborghuset. Since the inception of the church, she had had to
                                        move two times before coming to their present location. Gothenburg is a beautiful city and
                                        the second largest in Sweden. Sweden has a population of about 9 million but has produced
                                        sports, music and business stars. Do you know that Alfred Noble is Swedish. Swedish has also
                                        being so long involved in revival work around the world. We are proud to tell you about Ulf
                                        Ekman of Word of Life Uppsala
                                    </h4>
                                </div>)
                            },
                            {
                                title: (<h3>Early Beginnings</h3>),
                                content:(<div>
                                    <h4>
                                        At the inception many of the members were still trying to discover themselves in the land
                                        and did not have proper jobs to support themselves. However in the last one year there have
                                        been testimonies of business owner, house owner, and miraculous child birth and immigration
                                        permission.

                                        While at Agape Församlingen, we were told that new churches do not last six month before
                                        they fold up. To the glory of God the church spent two years there before moving their
                                        present location. Since the inception of the ministry, Jesus Connections has witnessed signs
                                        and wonders and evidence of changed lives through the word of God. Against all odds

                                        Jesus Connections has come to stay and to be part and a blessing to the country of Sweden.
                                        At present, the church is involved in the church planting commission of the RCCG and other
                                        charity and outreach projects in Gothenburg community. The emphasis of the word at Jesus
                                        connection is that there is a better way to live and expectations do become manifestations.
                                        As a result of such messages, we have witnessed Muslims and those rejected even by churches
                                        being used by God mightily today. To God be the glory.
                                    </h4>
                                </div>)
                            },
                            {
                                title: (<h3>The Bible</h3>),
                                content:(<div>
                                    <h4>
                                        We believe that the Bible is God’s Word. The truths revealed in it did not have their
                                        origin with men, but with God.
                                        The Holy Spirit inspired the human authors of the Bible.
                                        We therefore believe that the bible is without error, completely true, relevant and an
                                        up-to-date book.
                                        We receive the sixty-six books of the Old and New Testament as eternal, authoritative,
                                        coherent, complete and all sufficient, and the only infallible rule of faith.
                                        Its message is intended primarily for the human race.
                                        It is expressed in words and in terms, which human beings can understand.
                                        It’s central theme and purpose is the salvation of man.
                                    </h4>
                                    <Badge color={'rose'}>2 Timothy 3:16-17</Badge>
                                    <Badge color={'warning'}>2 Peter 1:20-21</Badge>
                                    <Badge color={'info'}>Psalm 12:6</Badge>
                                    <Badge color={'success'}>Matthew 24:35</Badge>
                                    <Badge color={'danger'}>Psalm 119:160</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>Trinity</h3>),
                                content:(<div>
                                    <h4>
                                        We believe in the Divine Trinity. One Triune God exists in three Persons – Father, Son
                                        and Holy Spirit – eternal in being, identical in nature, equal in power and glory and
                                        having the same attributes and perfections
                                    </h4>
                                    <Badge color={'rose'}>Matthew 3:16-17</Badge>
                                    <Badge color={'warning'}>2 Corinthians 13:14</Badge>
                                    <Badge color={'info'}>Hebrews 9:14</Badge>
                                    <Badge color={'success'}>1 Peter 1:2</Badge>
                                    <Badge color={'danger'}>1 John 5:7</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>Jesus Christ</h3>),
                                content: (<div>
                                    <h4>
                                        Jesus of Nazareth is the Christ, the Son of the living God. He came to the world
                                        purposely to save sinners according to the scriptures. He has existed throughout
                                        eternity, one of the persons of the Holy Trinity. He is the Son, the only begotten Son
                                        of God and the beloved of God. His coming to the world had been foretold before He ever
                                        came in the flesh to die for us. He was born by Virgin Mary, conceived of the Holy
                                        Spirit, without sin. He lived a perfect life, preached the gospel of the kingdom of God,
                                        performed miracles, healed the sick and raised the dead. He voluntarily surrendered his
                                        life to his enemies to be crucified. He then resurrected, and ascended to heaven to
                                        become our high priest. Jesus Christ will return to establish the kingdom of God on
                                        earth, and rule as King of Kings and Lord of Lords with His saints forever.
                                    </h4>
                                    <Badge color={'rose'}>Matthew 17:15-17</Badge>
                                    <Badge color={'warning'}>John 1:1-14</Badge>
                                    <Badge color={'info'}>Acts 2:32-33</Badge>
                                    <Badge color={'success'}>Hebrews 4:14-15</Badge>
                                    <Badge color={'danger'}>Revelation 1:13:16</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>Fall Of Man</h3>),
                                content: (<div>
                                    <h4>
                                        Man (Adam) was created in the image of God before whom he walked in innocence, holiness,
                                        and purity, but by voluntary disobedience and transgression, he fell from the glory of
                                        God to the depths of sin. The consequence of this is that all mankind became sinners by
                                        nature having been born after the fallen image of Adam. In his fallen state man is
                                        incapable of pleasing God or having any relationship with Him. Every man is totally
                                        inclined to evil, guilty and without excuse, deserving the condemnation and judgment of
                                        a just and Holy God.
                                    </h4>
                                    <Badge color={'rose'}>Genesis 1:26-31</Badge>
                                    <Badge color={'warning'}>Genesis 2:7</Badge>
                                    <Badge color={'info'}>Psalm 139:14</Badge>
                                    <Badge color={'success'}>Col. 1:16</Badge>
                                    <Badge color={'danger'}>Psalm 51:5</Badge>
                                    <Badge color={'primary'}>Mark 1:15</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>Salvation</h3>),
                                content: (<div>
                                    <h4>
                                        Sin is an inward spiritual attitude of rebellion towards God, which is expressed in
                                        outward acts of disobedience. Man in his fallen state cannot approach God or save
                                        himself and therefore needs a saviour. Jesus Christ came to save us from our sins.
                                        Himself without sin, He took our sins upon Him, died in our place, and rose again from
                                        the dead, that we might be forgiven and receive eternal life. The word salvation in the
                                        Greek means soteria which is translated saving or deliverance and preservation from
                                        destruction and judgment. To appropriate salvation, we must acknowledge our sins and
                                        repent from them; we must believe that Christ died for us, and rose again; we must
                                        receive the risen Christ as our personal Saviour, and we must publicly confess Him as
                                        our Lord.
                                    </h4>
                                    <Badge color={'rose'}>Hebrews 9:29</Badge>
                                    <Badge color={'warning'}>Romans 3:10, 23</Badge>
                                    <Badge color={'info'}>Acts 3:19</Badge>
                                    <Badge color={'success'}>Romans 10:8-13</Badge>
                                    <Badge color={'danger'}>Jude 3:24</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>Sanctification</h3>),
                                content: (<div>
                                    <h4>
                                        The word sanctification in the Greek language is
                                        <span className={"i"}> hagiasmos</span> meaning <span className={"green"}>(1)</span> to be apart from
                                        sin <span className={"green"}>(2)</span> consecrated unto God, to be conformed to
                                        His holiness, purity, and perfection of character. The scripture teaches that sanctified
                                        is what the believer is not a process, but a state, which the believer has already
                                        entered into once and for all.
                                        <br/>
                                        <span> Acts 20:32 </span>;
                                        <span> Romans 15:16 </span>;
                                        <span> 2Thessalonians 2:13 </span>;
                                        <span> Hebrews 10:10-14 </span>;
                                        <span> Ephesians 5:25-26 </span>;
                                        <span> 1Peter 1:2 </span>;
                                        <span> Acts 26:18 </span>
                                        <br/>
                                        and perfect is what he is
                                        expected to be. Perfection is a spiritual growth for those who have been sanctified and
                                        consecrated. Therefore it is the total yielding of one’s life to the Holy Spirit, living
                                        the crucified life; being an overcomer; and being conformed to the image of Christ
                                        Himself. Perfection and true holiness are impossible without the indwelling of the Holy
                                        Spirit. All attempts at achieving perfection without the Holy Spirit generally results
                                        in frustration and failure, inasmuch as flesh
                                        cannot crucify flesh.
                                    </h4>
                                    <Badge color={'rose'}>1 Peter 2:21-22</Badge>
                                    <Badge color={'warning'}>1 Peter 1:15</Badge>
                                    <Badge color={'info'}>John 3:8-9</Badge>
                                    <Badge color={'success'}>Corinthians 10:13</Badge>
                                    <Badge color={'danger'}>Matthew 5:48</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>Baptism Of The Holy Spirit</h3>),
                                content: (<div>
                                    <h4>
                                        The baptism in the Holy Ghost is a supernatural enduement with power from heaven to
                                        equip the Christian for effective witness and service.
                                        The initial evidence of Holy Spirit Baptism is speaking in unknown tongue as the spirit
                                        give utterance. The unknown tongue is a prayer language given to the believer,
                                        understood by God but unknown to man. It enables the Christian to build up his own
                                        spiritual life by direct and continual communion with God, and is the gateway into a
                                        life in which both the gifts and fruits of the Holy Ghost should be manifested. In the
                                        New Testament church, this experience was considered normal for all believers.
                                    </h4>
                                    <Badge color={'rose'}>Joel 2:28</Badge>
                                    <Badge color={'warning'}>Luke 3:16</Badge>
                                    <Badge color={'info'}>Acts 2:38-39</Badge>
                                    <Badge color={'success'}>Acts 19:2</Badge>
                                    <Badge color={'danger'}>Mark 16:17</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>Divine healing</h3>),
                                content: (<div>
                                    <h4>
                                        Sickness is a direct consequence of the fall of man and his continuance in sin.
                                        Redemption not only dealt with sin but also its consequences, which includes sickness
                                        and diseases. Christ died on the cross; bore not only our sins, but also our sicknesses.
                                        Healing for our bodies from God comes to us through appropriation of the finished work
                                        of Christ on the cross of Calvary by faith in the word of God and manifestation of the
                                        gift of healing. Not only believers receive healing for our bodies, but also we may
                                        minister healing and deliverance to others in the name of Jesus. This can be
                                        accomplished by laying on of hands, praying for others in absentia and by getting bible
                                        believing church elders to anoint them with oil in the name of the Lord.
                                    </h4>
                                    <Badge color={'rose'}>Luke 13:11,16</Badge>
                                    <Badge color={'warning'}>2 Corinthians 1:19-20</Badge>
                                    <Badge color={'info'}>1 John 3:8</Badge>
                                    <Badge color={'success'}>Mark 16:17-18</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>The Rapture Of The Church</h3>),
                                content: (<div>
                                    <h4>
                                        The rapture describes an event in the future when Jesus Christ will in the twinkling of
                                        an eye, change all believers (living and dead) to immortal, giving them a resurrected
                                        body, and catching them up to meet the Lord in the air. The word rapture simply means
                                        sudden snatching out of this world of the believers. The actual word used in the bible
                                        is caught up. This event marks the beginning of the seven years reign of Antichrist.1
                                        Thessalonians 4: 13-18
                                    </h4>
                                    <Badge color={'rose'}>1 Corinthians 15:23, 51-58</Badge>
                                    <Badge color={'warning'}>Phil 3:2-21</Badge>
                                    <Badge color={'info'}>1 John 3:1-3</Badge>
                                    <Badge color={'success'}>Luke 21:31-35</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>The Great Tribulation</h3>),
                                content: (<div>
                                    <h4>
                                        This is a seven-year span of future world history of the reign of antichrist following
                                        immediately after the rapture: It will be the darkest time the world has ever known. It
                                        is the day of vengeance of our Lord. Man’s cup of iniquity is filled to overflowing, and
                                        God brings judgment upon the earth for man’s rejection of His son. During this period,
                                        the people of Israel will turn to Christ, when they will be reconciled to God through
                                        Him whom they rejected and whom they asked Pilate to crucify.
                                    </h4>
                                    <Badge color={'rose'}>Daniel 9:24-27</Badge>
                                    <Badge color={'warning'}>Isaiah 61:2</Badge>
                                    <Badge color={'info'}>Revelation 19</Badge>
                                    <Badge color={'success'}>1 Thessalonians 1:10</Badge>
                                    <Badge color={'danger'}>Revelation 3:10</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>The Millenial Reign Of Jesus Christ</h3>),
                                content: (<div>
                                    <h4>
                                        The millennial age is period of one thousand years, in which the purpose of God is fully
                                        realized on the earth. It will be a time of theocratic kingdom. This period will come
                                        after the seven years reign of antichrist. After the Lord Jesus Christ has descended
                                        from heaven to earth and destroyed his enemies at the battle of Armageddon, He will the
                                        set up His holy government which is going to be worldwide. It will be a time of peace,
                                        joy, holiness, glory, comfort, justice, full knowledge, the removal of curse and
                                        sickness.
                                    </h4>
                                    <Badge color={'rose'}>Isaiah 11:1-10</Badge>
                                    <Badge color={'warning'}>Isaiah 35:1-10</Badge>
                                    <Badge color={'info'}>Zechariah 9:9-10</Badge>
                                    <Badge color={'success'}>Zechariah 14:16-17</Badge>
                                    <Badge color={'danger'}>Revelation 20:1-15</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>Judgement Seat Of Christ</h3>),
                                content: (<div>
                                    <h4>
                                        The judgment seat of Christ is the judgment of believers. It is not a judgment of
                                        condemnation but a judgment of reward where believers will be rewarded according to our
                                        faithfulness in our service to God, quality of our Christian walk and the use of our
                                        God-given gifts and talents.
                                    </h4>
                                    <Badge color={'rose'}>Romans 14:10</Badge>
                                    <Badge color={'warning'}>2 Corinthians 5: 10</Badge>
                                    <Badge color={'info'}>1 Peter 4:17</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>The Great White Throne Of Judgement</h3>),
                                content: (<div>
                                    <h4>
                                        This is the ultimate end of sin and rebellion against authority and the holiness of the
                                        Almighty God; to be cast forever into the lake of everlasting fire. This ultimate
                                        judgment of the Great White Throne is for unbelievers only. The only ones who may escape
                                        this final judgment are those who are written in the Book of Life. The names recorded in
                                        this book are the names of those who during their life on earth availed themselves,
                                        through faith, of God’s mercy and grace. Those who put their faith in Christ’s atoning
                                        sacrifice have passes through their own appropriate judgment before the judgment seat of
                                        Christ – not for condemnation but for assessment of their reward.
                                    </h4>
                                    <Badge color={'rose'}>Revelation 20:11-15</Badge>
                                    <Badge color={'warning'}>Ecclesiastes 12:14</Badge>
                                    <Badge color={'info'}>John 5:24</Badge>
                                    <Badge color={'success'}>2 Peter 3:9</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>The Eternal Heaven</h3>),
                                content: (<div>
                                    <h4>
                                        The present earth that is so marred and cursed by Satan’s evil will pass away after the
                                        Great White Throne Judgment. After the dissolution of the present (atmospheric) heaven
                                        and earth at the end of the one thousand years (the millennium), God will create a new
                                        heaven and a new earth better than anything this world has ever known. The new earth
                                        will be the Christian heaven. It is the glorious eternal home of born-again believers.
                                    </h4>
                                    <Badge color={'rose'}>Matthew 24:35</Badge>
                                    <Badge color={'warning'}>Revelation 21:1-5</Badge>
                                    <Badge color={'info'}>John 14:1-3</Badge>
                                    <Badge color={'success'}>Revelation 22:1-5</Badge>
                                    <Badge color={'danger'}>2 Peter 3:10</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>The Eternal Lake Of Fire(Hell)</h3>),
                                content: (<div>
                                    <h4>
                                        The lake of fire (commonly called hell) is the final abodes of Satan and those sinners
                                        who reject Jesus as their Lord and savior. Hell is the place of eternal torment for
                                        devil, his angels and all those who reject Christ as the savior.
                                    </h4>
                                    <Badge color={'rose'}>Revelations 19:7</Badge>
                                    <Badge color={'warning'}>Luke 16:19-31</Badge>
                                    <Badge color={'info'}>Revelations 20:10,15</Badge>
                                    <Badge color={'success'}>Matthew 25:33-34</Badge>
                                    <Badge color={'danger'}>John 14:1-3</Badge>
                                    <Badge color={'primary'}>Rev 7:15-17</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>Water Baptism</h3>),
                                content: (<div>
                                    <h4>
                                        Believing in Christ produces an inward change in our heart; baptism in water is an
                                        outward act of obedience, by which we testify of the change that has taken place in our
                                        hearts. By this act, we make ourselves one with Christ in His burial and in His
                                        resurrection; we are separated from the old life of sin and defeat; we come out of the
                                        water to lead a new life of righteousness and victory, made possible by God’s power in
                                        us.
                                    </h4>
                                    <Badge color={'rose'}>Mark 16:16</Badge>
                                    <Badge color={'warning'}>Matt 28:19-20</Badge>
                                    <Badge color={'info'}>Acts 8:37-39</Badge>
                                    <Badge color={'success'}>Romans 6:4</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>Restitution</h3>),
                                content: (<div>
                                    <h4>
                                        Restitution is the act of restoring anything to it’s rightful owner; act of giving an
                                        equivalent for loss or damage. Restitution makes a person pay back just debts, restore
                                        stolen or misappropriated articles, uncover his crimes and confess his lies. This is to
                                        be done whether the person injured knew it or not – God knows.

                                        <br/>

                                        <span>Note</span>: Restitution that would implicate others or bring injury or harm
                                        to others, needs
                                        care and God’s wisdom.
                                        In such cases, it is necessary to seek counseling from a faithful, experienced,
                                        competent, matured Christian teacher and preacher (who, of course, believes and teaches
                                        the whole counsel of God).
                                    </h4>
                                    <Badge color={'rose'}>Genesis 20:1-18</Badge>
                                    <Badge color={'warning'}>Genesis 32:9-32</Badge>
                                    <Badge color={'info'}>Luke 19:8-10</Badge>
                                    <Badge color={'success'}>Philemon 7:21</Badge>
                                    <Badge color={'danger'}>Acts 24:16</Badge>
                                </div>)
                            },
                            {
                                title: (<h3>The Lord's Supper</h3>),
                                content: (<div>
                                    <h4>
                                        The Lord’s Supper, consisting of the elements – bread and the fruit of the vine is the
                                        symbol expressing our sharing the divine nature of our Lord Jesus Christ. It is a
                                        memorial of His suffering, death and a prophecy of His second coming. Jesus Christ
                                        commanded the church to do it in His remembrance. The bible record also shows the early
                                        church took this commandment seriously.
                                    </h4>
                                    <Badge color={'rose'}>Luke 22: 14-19</Badge>
                                    <Badge color={'warning'}>Acts 2: 42,46</Badge>
                                    <Badge color={'info'}>2 Peter 1:4</Badge>
                                    <Badge color={'success'}>1 Corinthians 11:26</Badge>
                                </div>)
                            },
                        ]}
                    />
                </div>
            </div>

        </div>
    );
}

export default AboutUsPage;
