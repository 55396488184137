import {getWordForTheMonth} from "../../components/rccgjc/word-for-the-month/wordForTheMonthRequests";

export const LOAD_WORD_FOR_THE_MONTH = 'LOAD_WORD_FOR_THE_MONTH';
export const SET_STATUS = 'SET_STATUS';
export const SET_ERROR = 'SET_ERROR';

const load = payload => ({ type: LOAD_WORD_FOR_THE_MONTH, payload })
const setStatus = payload => ({ type: SET_STATUS, payload })
const setError = payload => ({ type: SET_ERROR, payload })

export const loadWordForTheMonth = () => dispatch => {
    dispatch(setStatus('loading'))
    getWordForTheMonth()
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(resp => {
            dispatch(setStatus('done'))
            dispatch(load(resp))
        })
        .catch(err => {
            dispatch(setStatus('failed'))
            dispatch(setError(err))
        })
}
