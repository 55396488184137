import {
    gql
} from "@apollo/client";

export const GIVE_ONLINE = gql`
mutation give($request: GivingRequest) {
  giveOnline(request: $request) {
    amountGiven
    eventId
    giverEmail
    modeOfGiving
    dateAndTimeOfGiving
  }
}
`;
