import React, {Fragment, useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";


import {useDispatch} from "react-redux";
import {setThemeForHeader} from "../actions/app/appActions";
import Mp3Player from "../components/rccgjc/replay/mp3-player";
import MessageList from "../components/rccgjc/replay/message-list";
import GridItem from "../components/Grid/GridItem";
import CardBody from "../components/Card/CardBody";
import Card from "../components/Card/Card";
import CustomLinearProgress from "../components/CustomLinearProgress/CustomLinearProgress";
import HeaderMargin from "../components/rccgjc/header-margin/header-margin";
import JCSubscribeToNewsletter from "../components/rccgjc/modals/jc-subscribe-to-newsletter";
import {useQuery} from "@apollo/client";
import {MP3_MESSAGES} from "../graphql-config/query/event";

const useStyles = makeStyles(teamsStyle);

const ReplayPage = () => {
    const [messages, setMessages] = useState([])
    const [status, setStatus] = useState('')
    const [selectedMessage, setSelectedMessage] = useState({})

    const { data, error } = useQuery(MP3_MESSAGES);

    useEffect(()=>{
        if (data) {
            let msgs = data.allMp3Messages;

            setMessages(msgs)
            setSelectedMessage(msgs[0])
            setStatus("done")
        }
    }, [data]);

    useEffect(()=>{
        if (error) {
            setStatus("failed for event");
        }
    },[error]);

    const selectMessage = index => {
        let foundMessage = messages.find((message, i) => i === index)
        setSelectedMessage(foundMessage)
    }

    const dispatch = useDispatch();
    useEffect(() => {
            dispatch(setThemeForHeader('white'))
        },
        []);

    const classes = useStyles();
    return (
        <div className="cd-section" >
            <div className={classes.team}>
                <div className={classes.container}>
                    {
                        status === 'done' ?
                            <Fragment>
                            <GridContainer>
                                <Mp3Player classes={classes}
                                           speaker={selectedMessage.speaker}
                                           date={selectedMessage.date}
                                           title={selectedMessage.messageTitle}
                                           link={selectedMessage.messageLink}/>
                            </GridContainer>
                            <GridContainer>
                                <MessageList messages={messages} classes={classes} selectMessage={selectMessage}/>
                            </GridContainer>
                            </Fragment>
                            :
                            status === 'loading' ?
                                <Fragment>
                                    <GridContainer>
                                        <GridItem
                                            xs={12}
                                            sm={8}
                                            md={8}
                                            className={
                                                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                                            }
                                        >
                                            <div style={{
                                                marginTop: '15rem'
                                            }}>
                                                <Card profile plain>
                                                    <CardBody>
                                                        <CustomLinearProgress
                                                            variant="indeterminate"
                                                            color="info"
                                                            value={60}
                                                        />
                                                        <h2 className={'title'}>loading...</h2>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <HeaderMargin marginWithUnit={'15rem'}/>
                                        </GridItem>
                                    </GridContainer>
                                </Fragment>
                                :
                                <Fragment>
                                    <GridContainer>
                                        <GridItem
                                            xs={12}
                                            sm={8}
                                            md={8}
                                            className={
                                                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                                            }
                                        >
                                            <div style={{
                                                marginTop: '15rem'
                                            }}>
                                                <Card profile plain>
                                                    <CardBody>
                                                        <h2 className={'title'}>Could not load messages at this time</h2>
                                                        <h4>
                                                            please reload this page in a few minutes
                                                        </h4>
                                                        <JCSubscribeToNewsletter buttonColor={'danger'}/>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <HeaderMargin marginWithUnit={'10rem'}/>
                                        </GridItem>
                                    </GridContainer>
                                </Fragment>
                    }

                </div>
            </div>
        </div>
    );
}

export default ReplayPage;
