import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../../CustomButtons/Button";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Card from "../../Card/Card";
import NewsletterForm from "./forms/newsletter-form";
import NewsletterFailed from "./status/newsletter-failed";
import JCGridContainer from "../grid/jc-grid-container";
import JCGridItem from "../grid/jc-grid-item";
import NewsletterSuccess from "./status/newsletter-success";
import NewsletterLoading from "./status/newsletter-loading";

const JCNewsletterCard = ({ classes,
                              setChecked,
                              checked,
                              email,
                              emailChange,
                              send,
                              dispatch,
                              status, clear}) => (
    <Card plain className={classes.modalSignupCard}>
        <DialogTitle
            id="signup-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
        >
            <Button
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => clear()}
            >
                {" "}
                <Close className={classes.modalClose}/>
            </Button>
            {
                status === '' ?
                    <h5 className={classes.cardTitle + " " + classes.modalTitle}>
                        Signup for newsletter
                    </h5>
                    :
                    null
            }

        </DialogTitle>
        <DialogContent
            id="signup-modal-slide-description"
            className={classes.modalBody}
        >
            <JCGridContainer justifyContent={'center'}>
                <JCGridItem xs={12} sm={5} md={5}>
                    {
                        status === 'failed' ?
                            <NewsletterFailed classes={classes} dispatch={dispatch} clear={clear}/>
                            :
                            status === 'done' ?
                                <NewsletterSuccess classes={classes} dispatch={dispatch} clear={clear}/>
                                :
                                status === 'loading' ?
                                    <NewsletterLoading classes={classes} dispatch={dispatch} clear={clear}/>
                                    :
                            <NewsletterForm
                                send={send}
                                email={email}
                                setChecked={setChecked}
                                checked={checked}
                                dispatch={dispatch}
                                classes={classes}
                                emailChange={emailChange}/>
                    }
                </JCGridItem>
            </JCGridContainer>
        </DialogContent>
    </Card>
)

export default JCNewsletterCard;
