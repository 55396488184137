import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";

import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import JCNewsletterCard from "./jc-newsletter-card";
import {useDispatch, useSelector} from "react-redux";
import {
    HIDE_SIGN_UP_MODAL,
    SHOW_SIGN_UP_MODAL
} from "../../../actions/signupmodal/signupmodalActions";
import {useMutation} from "@apollo/client";
import {REGISTER_NEWSLETTER_USER} from "../../../graphql-config/query/registration";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

const JCSubscribeToNewsletter = ({ buttonColor, simple }) => {
    const dispatch = useDispatch();
    const modalState = useSelector(state => state.signupModal)

    const [registerNewsletterContact] = useMutation(REGISTER_NEWSLETTER_USER);

    const [checked, setChecked] = useState(false);
    const [status, setStatus] = useState('');
    const [email, setEmail] = useState('');

    const onHandleEmail = event => {
        setEmail(event.target.value)
    }

    const sendContact = () => {
        if (email === "" || !email.trim()) {
            setStatus("failed")
            return;
        }
        setStatus("loading")

        registerNewsletterContact(
            {
                variables: {
                    email: email
                },
                onCompleted() {
                    setStatus("done")
                },
                onError() {
                    setStatus("failed")
                }
            }
        )
    }

    const clear = () => {
        setStatus('');
        setEmail('');
        setChecked(false);
        dispatch({ type: HIDE_SIGN_UP_MODAL })
    }
    const classes = useStyles();
    return (
        <div>
            <Button simple={simple} color={ buttonColor ? buttonColor :'transparent'}
                    onClick={() => dispatch({ type: SHOW_SIGN_UP_MODAL})}>
                subscribe to newsletter
            </Button>
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalSignup
                }}
                open={modalState.showModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => dispatch({ type: HIDE_SIGN_UP_MODAL })}
                aria-labelledby="signup-modal-slide-title"
                aria-describedby="signup-modal-slide-description"
            >
                <JCNewsletterCard
                    classes={classes}
                    dispatch={dispatch}
                    checked={checked}
                    setChecked={setChecked}
                    email={email}
                    emailChange={onHandleEmail}
                    send={sendContact}
                    status={status} clear={clear}/>
            </Dialog>
        </div>
    );
}

export default JCSubscribeToNewsletter;
