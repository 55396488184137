import React from "react";
import Carousel from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(carouselStyle);

const LandingPageCarousel = ({ details }) => {
    const classes = useStyles();
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };
    return (
        <div className={classes.section} id="carousel">
            <div className={classes.container}>
                <GridContainer>
                    <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
                        <Card>
                            <Carousel {...settings}>
                                {
                                    details.map((detail, i) => <div key={i}>
                                        <img src={detail.link} alt={`${i + 1} slide`} className="slick-image" />
                                        <div className="slick-caption">
                                        <h4>
                                            {detail.name}
                                        </h4>
                                        </div>
                                    </div>)
                                }
                            </Carousel>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
export default LandingPageCarousel;
