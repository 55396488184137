import React from "react";
import Tooltip from "@material-ui/core/Tooltip";


import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import {makeStyles} from "@material-ui/core/styles";
const useStyles  = makeStyles(tooltipsStyle)

const JCToolTip = ({ message, placement, children }) => {
    const tooltips = useStyles();

    return <Tooltip title={message}
                    placement={placement ? placement : "top"}
                    classes={{ tooltip: tooltips.tooltip }}>
        {children}
    </Tooltip>
}
export default JCToolTip;
