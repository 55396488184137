import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import vastraffik from "../../../images/svgs/vasttrafik-ab-vector-logo.svg";


import freeDemoStyle from "assets/jss/material-kit-pro-react/views/presentationSections/freeDemoStyle.js";
import GetToChurchModal from "./modals/get-to-church-modal";
import Button from "../../CustomButtons/Button";

const useStyles = makeStyles(freeDemoStyle);

const GetToChurch = () => {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer justifyContent="center">
                    <GridItem
                        md={5}
                        sm={8}
                        className={classes.mlAuto + " " + classes.mrAuto}
                    >
                        <div className={classes.iconGithub}>
                            <div>
                                <img src={vastraffik} alt={'vastraffik'} style={{
                                    width: '10rem',
                                    height: '8rem'
                                }}/>
                            </div>
                        </div>
                        <div>
                            <h2 className={classes.title}>Find your way to church</h2>
                            <h5 className={classes.description}>
                                We've included a travel tool for you. If you live within Västra Götalands regionen,
                                you can take advantage of our travel planner to plan your next trip to our church.
                            </h5>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Button href={'https://goo.gl/maps/b2ZYX4yP2qHsNjd28'} target={'_blank'} rel={'noreferrer'} color={'info'}>
                                Google maps
                            </Button>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Button href={'https://www.vasttrafik.se'} target={'_blank'} rel={'noreferrer'} color={'danger'}>
                                Vastraffik
                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}

export default GetToChurch;
