import React from "react";
import CardHeader from "../../../Card/CardHeader";

const FormHeader = ({ classes }) => (
    <CardHeader
        contact
        color="info"
        className={classes.textCenter}
    >
        <h4 className={classes.cardTitle}>Contact Us</h4>
    </CardHeader>
)

export default FormHeader;
