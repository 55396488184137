import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";

import { isValidEmail, isValidPhoneNumber } from "../validations";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import image from "images/gbg/gbg1.jpg";
import {countryList} from "../country-list/country-list";
import RegisterForm from "../components/rccgjc/register/form";
import Danger from "../components/Typography/Danger";
import ConfirmModal from "../components/rccgjc/register/confirmModal";
import {Navigate} from "react-router-dom";
import HeaderMargin from "../components/rccgjc/header-margin/header-margin";
import {useDispatch} from "react-redux";
import {setThemeForHeader} from "../actions/app/appActions";
import {useMutation} from "@apollo/client";
import {REGISTER_USER} from "../graphql-config/query/registration";

const useStyles = makeStyles(signupPageStyle);

const RegistrationPage = () => {
    const [checked, setChecked] = useState(false);
    const [country, setCountry] = useState('')
    const [countries, setCountries] = useState([])
    const [birthdate, setBirthDate] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [postCode, setPostCode] = useState('')
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const [errors, setErrors] = useState(false)
    const [isWorker, setIsWorker] = useState(false)
    const [libraryOptIn, setLibraryOptin] = useState(false)
    const [show, setShow] = useState(false)
    const [status, setStatus] = useState('')
    const [redirectToHome, setRedirectToHome] = useState(false)

    const dispatch = useDispatch();

    useEffect(()=> {
            dispatch(setThemeForHeader('white'))
        },

        [])

    const classes = useStyles();

    const handleCountry = event => {
        setCountry(event.target.value)
        setCountries(countryList.filter(c => c.toLowerCase().includes(country.toLowerCase())))
    }

    const selectCountry = c => {
        setCountry(c)
        setCountries([])
    }

    const handleBirthdate = e => {
        setBirthDate(e._d.toLocaleDateString('sv-SE'))
    }

    const handleFirstName = e => {
        if (firstNameError) {
            setFirstNameError(false)
            setErrors(false)
        }

        setFirstName(e.target.value)
    }

    const handleLastName = e => {
        if (lastNameError) {
            setLastNameError(false)
            setErrors(false)
        }
        setLastName(e.target.value)
    }

    const handleEmail = e => {
        if (emailError){
            setEmailError(false)
            setErrors(false)
        }
        setEmail(e.target.value)
    }

    const handlePhoneNumber = e => {
        if (phoneNumberError) {
            setErrors(false)
            setPhoneNumberError(false)
        }
        setPhoneNumber(e.target.value)
    }

    const handlePostCode = e => {
        setPostCode(e.target.value)
    }
    const handleAddress = e => {
        setAddress(e.target.value)
    }

    const superClear = () => {
        setEmail('')
        setFirstName('')
        setLastName('')
        setPhoneNumber('')
        setAddress('')
        setPostCode('')
        setCountry('')
        setCountries([])
        setBirthDate('')
        setIsWorker(false)
        setChecked(false)
        setErrors(false)
        setShow(false)
        setLibraryOptin(false);
    }

    const register = () => {
        setStatus('')
        setErrors(false)
        let containsErrors = false;

        if (!checked) {
            setChecked(true)
        }

        if (firstName === "" || !firstName.trim()) {
            setFirstNameError(true)
            containsErrors = true;
        }

        if (lastName === "" || !lastName.trim()) {
            setLastNameError(true)
            containsErrors = true
        }

        if (!isValidEmail(email)) {
            setEmailError(true)
            containsErrors = true
        }

        if (phoneNumber !== '' && !isValidPhoneNumber(phoneNumber)) {
            setPhoneNumberError(true)
            containsErrors = true
        }

        if (containsErrors) {
            setErrors(true)
            return
        }
        setShow(true)
    }

    const [registerUser] = useMutation(REGISTER_USER);

    const confirm = () => {
        registerUser({
            variables: {
                request: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phoneNumber: phoneNumber,
                    address: `${address},${postCode},${country}`,
                    dateOfBirth: birthdate,
                    worker: isWorker,
                    consent: true,
                    userType: "User",
                    libraryOptIn: libraryOptIn
                }
            },
            onCompleted() {
                setStatus('done')
            },
            onError(err) {
                console.log(err)
                setStatus('failed')
            }
        })
    }

    if (redirectToHome) {
        return <Navigate to={'/'}/>
    }
    return (
        <div>
            <HeaderMargin marginWithUnit={'2rem'}/>
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.container}>
                    <GridContainer justifyContent="center">
                        <GridItem xs={12} sm={10} md={10}>
                            <Card className={classes.cardSignup}>
                                <h2 className={classes.cardTitle}>Register</h2>
                                <CardBody>
                                    <GridContainer justifyContent="center">
                                        <GridItem xs={12} sm={5} md={5}>
                                            <RegisterForm classes={classes}
                                                          firstName={firstName}
                                                          handleFirstName={handleFirstName}
                                                          lastName={lastName}
                                                          handleLastName={handleLastName}
                                                          email={email}
                                                          handleEmail={handleEmail}
                                                          phoneNumber={phoneNumber}
                                                          handlePhoneNumber={handlePhoneNumber}
                                                          address={address}
                                                          handleAddress={handleAddress}
                                                          postCode={postCode}
                                                          handlePostCode={handlePostCode}
                                                          countries={countries}
                                                          country={country}
                                                          selectCountry={selectCountry}
                                                          handleCountry={handleCountry}
                                                          handleBirthdate={handleBirthdate}
                                                          setChecked={setChecked}
                                                          checked={checked}
                                                          firstNameError={firstNameError}
                                                          lastNameError={lastNameError}
                                                          emailError={emailError}
                                                          phoneNumberError={phoneNumberError}
                                                          setLibraryOptIn={setLibraryOptin}
                                                          libraryOptIn={libraryOptIn}
                                                          isWorker={isWorker} setIsWorker={setIsWorker}>
                                                <div className={classes.textCenter}>
                                                    <ConfirmModal
                                                        send={register}
                                                        show={show}
                                                        setShow={setShow} firstName={firstName}
                                                        lastName={lastName} address={`${address}\n${postCode}\n${country}`}
                                                        phoneNumber={phoneNumber} dob={birthdate} email={email}
                                                        worker={isWorker} status={status} confirm={confirm}
                                                        redirectToHome={setRedirectToHome}
                                                        superClear={superClear}/>
                                                </div>
                                                <div className={classes.textCenter}>
                                                    {
                                                        errors ?
                                                            <Danger>
                                                                Please correct the fields marked in red
                                                            </Danger>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </RegisterForm>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}

export default RegistrationPage;
