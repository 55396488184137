import {doRequest} from "../../../httpClient";

export const getWordForTheMonth = () => {
    const settings = {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    }

    const url = "/web/internal/wordforthemonth/month";
    return doRequest(url, settings)
}
