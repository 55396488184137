import React from "react";
import CustomInput from "../../CustomInput/CustomInput";
import Button from "../../CustomButtons/Button";

const RegisterCountry = ({ countries, country, selectCountry, handleCountry }) => (
    <div>
        <CustomInput
            formControlProps={{
                fullWidth: true,
            }}
            inputProps={{
                placeholder: "Country",
                value: country,
                onChange: handleCountry
            }}
        />
        {
            countries.length >= 1 && countries.length < 5 && country !== '' ?
                countries.map((c, i) =>
                    <Button simple size='sm'
                            color={'info'} key={i}
                            onClick={()=> selectCountry(c)}>{c}</Button>)
                :
                null
        }
    </div>
)

export default RegisterCountry;
