import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import client from './graphql-config/graphql-config';
import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from "redux";
import rootReducer from "./reducers";
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from "redux-thunk";

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunk)
));

import {
    ApolloProvider
} from "@apollo/client";

ReactDOM.render(
            <Provider store={store}>
                <ApolloProvider client={client}>
                    <App/>
                </ApolloProvider>
            </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
