import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/preFooter.js";

const useStyles = makeStyles(styles);

const SocialMediaFooter = () => {
    const classes = useStyles();
    return (
        <div>
            <div
                className={classNames(
                    classes.socialLine,
                    classes.textCenter,
                    classes.white,
                    classes.bigIcons
                )}
            >
                <div className={classes.container}>
                    <GridContainer justifyContent="center">
                        <GridItem xs={12} sm={2} md={2} className={classes.border}>
                            <Button
                                color="twitter"
                                justIcon
                                simple
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                <i className="fab fa-twitter" />
                            </Button>
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2} className={classes.border}>
                            <Button
                                color="facebook"
                                justIcon
                                simple
                                href="https://www.facebook.com/JCRCCG/"
                                target={'_blank'}
                            >
                                <i className="fab fa-facebook" />
                            </Button>
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2} className={classes.border}>
                            <Button
                                color="youtube"
                                justIcon
                                simple
                                href="https://www.youtube.com/channel/UCkG2qJhaWpL1L24_Wr0G2_w"
                                target={'_blank'}
                            >
                                <i className="fab fa-youtube" />
                            </Button>
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2} className={classes.border}>
                            <Button
                                color="instagram"
                                justIcon
                                simple
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                <i className="fab fa-instagram" />
                            </Button>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
};

export default SocialMediaFooter;
