import React, {useEffect} from "react";
import ContactUs from "../components/rccgjc/contact-us/contact-us";
import {useDispatch} from "react-redux";
import {setThemeForHeader} from "../actions/app/appActions";
const ContactUsPage = () => {
    const dispatch = useDispatch();

    useEffect(()=> {
            dispatch(setThemeForHeader('white'))
        },
        [])

    return (
        <div>
            <div style={{
                marginTop: '4rem'
            }}/>
            <ContactUs/>
        </div>
    )
}
export default ContactUsPage;
