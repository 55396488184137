import React, {Fragment} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import {Email, LocationOn, PersonAdd, Phone, CalendarToday, Favorite} from "@material-ui/icons";
import CustomLinearProgress from "../../CustomLinearProgress/CustomLinearProgress";
import Info from "../../Typography/Info";
import Success from "../../Typography/Success";
import Danger from "../../Typography/Danger";
import HeaderMargin from "../header-margin/header-margin";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

const ConfirmModal = ({ send, show, setShow, firstName,
                          lastName, email, address, phoneNumber, dob, worker, status,
                      redirectToHome, superClear, confirm }) => {

    const classes = useStyles();
    return (
        <div>
            <Button color="info" onClick={() => send()}>
                register
            </Button>
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={show}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShow(false)}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => setShow(false)}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    {
                        status === 'done' ?
                            <h4 className={classes.modalTitle}>Congratulations</h4>
                            :
                            <h4 className={classes.modalTitle}>Confirm details</h4>
                    }

                </DialogTitle>

                {
                    status === 'loading' ?
                        <div className={classes.modalBody}>
                            <CustomLinearProgress
                                variant="indeterminate"
                                color="info"
                                value={60}
                            />
                            <div className={classes.textCenter}>
                                <Info>
                                    one moment please
                                </Info>
                            </div>

                        </div>
                        :

                        status === 'done' ?
                            <div>
                                <DialogContent
                                    id="classic-modal-slide-description"
                                    className={classes.modalBody}
                                >
                                    <Success>
                                        <Favorite/>
                                        Congratulations, you're now registered.
                                        We've sent you an email with more details
                                    </Success>
                                </DialogContent>
                                <DialogActions className={classes.modalFooter}>
                                    <Button simple color="success" onClick={()=> redirectToHome(true)}>home</Button>
                                    <Button color="success" onClick={()=> superClear()}>close</Button>
                                </DialogActions>
                            </div>
                            :

                        <Fragment>
                            <DialogContent
                                id="classic-modal-slide-description"
                                className={classes.modalBody}
                            >
                                <p><span style={{
                                    marginRight: '10px'
                                }}><PersonAdd/></span>{`${firstName} ${lastName}`}</p>

                                <p><span style={{
                                    marginRight: '10px'
                                }}><Email/></span>{`${email}`}</p>

                                {
                                    phoneNumber ?
                                        <p><span style={{
                                            marginRight: '10px'
                                        }}><Phone/></span>{`${phoneNumber}`}</p>
                                        :
                                        null
                                }


                                {
                                    address ?
                                        <p><span style={{
                                            marginRight: '10px'
                                        }}><LocationOn/></span>{`${address}`}</p>
                                        :
                                        null
                                }


                                {
                                    dob ?
                                        <p><span style={{
                                            marginRight: '10px'
                                        }}><CalendarToday/></span>{`${dob}`}</p>
                                        :
                                        null
                                }

                                {
                                    worker ?
                                        <p>You've selected to be added as a worker</p>
                                        :
                                        null
                                }

                            </DialogContent>
                            <DialogActions className={classes.modalFooter}>
                                <Button
                                    onClick={() => setShow(false)} color="secondary">
                                    edit
                                </Button>
                                <Button color="info" onClick={()=> confirm()}>register</Button>
                            </DialogActions>
                            {
                                status === 'failed' ?
                                    <div style={{
                                        textAlign: 'center'
                                    }}>
                                        <Danger>
                                            Seems like you're already a member.
                                            <br/>
                                            Please edit your details
                                        </Danger>
                                        <HeaderMargin marginWithUnit={'2rem'}/>
                                    </div>

                                    :
                                    null
                            }
                        </Fragment>

                }

            </Dialog>
        </div>
    );
}
export default ConfirmModal
