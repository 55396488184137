import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";

import { isValidEmail } from "../validations";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import image from "images/gbg/gbg1.jpg";

import HeaderMargin from "../components/rccgjc/header-margin/header-margin";
import {useDispatch} from "react-redux";
import {setThemeForHeader} from "../actions/app/appActions";
import RegisterInput from "../components/rccgjc/register/input";
import CustomInput from "../components/CustomInput/CustomInput";
import Button from 'components/CustomButtons/Button'
import JCToolTip from "../components/rccgjc/tooltip/jc-tool-tip";
import {useMutation} from "@apollo/client";
import {SAVE_STUDY_NOTE, SEND_STUDY_NOTE} from "../graphql-config/query/study-note";

const useStyles = makeStyles(signupPageStyle);

const StudyPage = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [notes, setNotes] = useState('');
    const [noteTitle, setNoteTitle] = useState('');
    const [status, setStatus] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [notesError, setNotesError] = useState(false)
    const [noteId, setNoteId] = useState(null)

    const [saveStudyNote] = useMutation(SAVE_STUDY_NOTE);
    const [sendStudyNote] = useMutation(SEND_STUDY_NOTE);

    const dispatch = useDispatch();

    useEffect(()=> {
            dispatch(setThemeForHeader('white'))
        },
        [])

    const classes = useStyles();

    const handleEmailChange = e => {
        if (emailError){
            setEmailError(false)
        }
        setEmail(e.target.value)
    }

    const handleFirstNameChange = e => {
        setFirstName(e.target.value)
    }

    const handleNotesChange = e => {
        setNotes(e.target.value)
    }

    const handleNoteTitleChange = e => {
        if (notesError){
            setNotesError(false)
        }
        setNoteTitle(e.target.value)
    }

    const save = () => {
        if (!isValidEmail(email)){
            setEmailError(true)
            return
        }

        if (notes.length < 10) {
            setNotesError(true)
            return
        }

        setStatus('loading');

        saveStudyNote(
            {
                variables: {
                    request: {
                        email: email,
                        firstName: firstName === "" ? null : firstName,
                        lastName: null,
                        noteTitle: noteTitle === "" ? null : noteTitle,
                        notes: notes,
                        bibleVerse: null,
                        verses: null,
                        bibleState: null,
                        noteId: noteId
                    }
                },
                onCompleted(data) {
                    const { studyNoteId } = data.saveNote;
                    setStatus('saved')
                    setNoteId(studyNoteId)
                    setTimeout(()=> {
                        setStatus('')
                    }, 3000)
                },
                onError() {
                  setStatus("failed")
                }
            }
        )
    }

    const send = () => {
        if (!isValidEmail(email)){
            setEmailError(true)
            return
        }

        if (notes.length < 10) {
            setNotesError(true)
            return
        }

        setStatus('loading');

        sendStudyNote(
            {
                variables: {
                    request: {
                        email: email,
                        firstName: firstName === "" ? null : firstName,
                        lastName: null,
                        noteTitle: noteTitle === "" ? null : noteTitle,
                        notes: notes,
                        bibleVerse: null,
                        verses: null,
                        bibleState: null,
                        noteId: noteId
                    }
                },
                onCompleted(data) {
                    const sent = data.sendNote;
                    setStatus(sent ? 'sent' : '');
                    setTimeout(()=> {
                        setStatus('')
                    }, 3000)
                },
                onError() {
                    setStatus("failed")
                }
            }
        );
    }
    return (
        <div>
            <HeaderMargin marginWithUnit={'2rem'}/>
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.container}>
                    <GridContainer justifyContent="center">
                        <GridItem xs={12} sm={10} md={10}>
                            <Card className={classes.cardSignup}>
                                <h2 className={classes.cardTitle}>Study note</h2>
                                <CardBody>
                                    <GridContainer justifyContent="center">
                                        <GridItem xs={12} sm={5} md={5}>
                                            {
                                                status === 'loading' ?
                                                    <small>one moment please...</small>
                                                    :
                                                    status === 'saved' ?
                                                        <small>saved</small>
                                                        :
                                                        status === 'sent' ?
                                                            <small>sent</small>
                                                            :
                                                            null
                                            }
                                            <form className={classes.form}>
                                                <RegisterInput classes={classes}
                                                               onChange={handleEmailChange}
                                                               value={email} placeHolder={'your@email.com'} error={emailError}
                                                               type={'email'}/>

                                                <RegisterInput classes={classes}
                                                               onChange={handleFirstNameChange}
                                                               value={firstName} placeHolder={'your name'}/>

                                                                   <RegisterInput classes={classes}
                                                                                  onChange={handleNoteTitleChange}
                                                                                  value={noteTitle} placeHolder={'a title for your note.'}/>
                                                <CustomInput
                                                    labelText="Your study notes"
                                                    id="message"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        multiline: true,
                                                        rows: 10,
                                                        value: notes,
                                                        onChange: handleNotesChange,
                                                        error: notesError
                                                    }}
                                                />
                                            </form>
                                            <div className={classes.textCenter}>
                                                <JCToolTip message={'Save a copy of your message and return to it any time'}>
                                                    <Button color={'info'} onClick={save} disabled={status === 'loading'}>
                                                        Save
                                                    </Button>
                                                </JCToolTip>
                                                <JCToolTip message={'Send this message to yourself or to any of your friends'}>
                                                    <Button color={'rose'} disabled={status === 'loading' || noteId === null} onClick={send}>
                                                        Send
                                                    </Button>
                                                </JCToolTip>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    )
}
export default StudyPage;
