import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";


import Close from "@material-ui/icons/Close";
import Verified from "@material-ui/icons/VerifiedUser";
import Data from "@material-ui/icons/DataUsage";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import style from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import HeaderMargin from "../header-margin/header-margin";
import Danger from "../../Typography/Danger";
import CardBody from "../../Card/CardBody";
import CustomLinearProgress from "../../CustomLinearProgress/CustomLinearProgress";
import {isValidEmail} from "../../../validations";
import {useMutation} from "@apollo/client";
import {FORGOT_TITHE} from "../../../graphql-config/query/tithe";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

const ForgotTitheModal = () => {
    const [signupModal, setSignupModal] = useState(false);
    const [email, setEmail] = useState('')
    const [status, setStatus] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [emailError, setEmailError] = useState(false)


    const [forgotTitheNumber, { reset }] = useMutation(FORGOT_TITHE);

    const handleEmailChange = e => {
        if (email !== '') {
            setErrorMessage('')
            setEmailError(false)
        }
        setEmail(e.target.value)
    }

    const send = () => {
        forgotTitheNumber(
            {
                variables: {email: email},
                onCompleted() {
                    setStatus("done")
                }
            }
        )
    }

    const close = () => {
        reset();
        setStatus('');
        setEmail('');
        setSignupModal(false);
    }

    const classes = useStyles();
    return (
        <div>
            <HeaderMargin marginWithUnit={'1rem'}/>
            <Button simple color={'transparent'} onClick={() => setSignupModal(true)}>
                tithe number
            </Button>
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalSignup
                }}
                open={signupModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={close}
                aria-labelledby="signup-modal-slide-title"
                aria-describedby="signup-modal-slide-description"
            >
                <Card plain className={classes.modalSignupCard}>
                    <DialogTitle
                        id="signup-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            onClick={close}
                        >
                            {" "}
                            <Close className={classes.modalClose}/>
                        </Button>
                        <h5 className={classes.cardTitle + " " + classes.modalTitle}>
                            Request Tithe Number
                        </h5>
                    </DialogTitle>
                    <DialogContent
                        id="signup-modal-slide-description"
                        className={classes.modalBody}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                                <InfoArea
                                    className={classes.infoArea}
                                    title="Secure"
                                    description={
                                        <p>
                                            Your tithe number is unique number which allows us to record your tithes.
                                            Your tithe number contains a connection to your details and should be kept
                                            secret and secure.
                                        </p>
                                    }
                                    icon={Verified}
                                    iconColor="primary"
                                />
                                <InfoArea
                                    className={classes.infoArea}
                                    title="Available Data"
                                    description={
                                        <p>
                                            You can always request a new tithe number if you've somehow misplaced your current tithe number.
                                            No one else in our system can see your details except our
                                            system admins so you can be sure your information is secure.
                                        </p>
                                    }
                                    icon={Data}
                                    iconColor="primary"
                                />
                            </GridItem>
                            {
                                status === 'done' ?
                                    <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
                                        <HeaderMargin marginWithUnit={'6rem'}/>
                                        <form className={classes.form}>
                                            <div className={classes.textCenter}>
                                                <h5>
                                                    Thank you. If you're a part of our online church, we'll send your tithe number
                                                    otherwise we'll create a new one for you
                                                </h5>
                                                <Button color="primary" onClick={close}>
                                                    close
                                                </Button>
                                            </div>
                                        </form>
                                    </GridItem>
                                    :
                                    status === 'loading' ?
                                        <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
                                            <HeaderMargin marginWithUnit={'8rem'}/>
                                            <Card profile plain>
                                                <CardBody>
                                                    <CustomLinearProgress
                                                        variant="indeterminate"
                                                        color="primary"
                                                        value={60}
                                                    />
                                                    <h4 className={'title'}>one moment please...</h4>
                                                </CardBody>
                                            </Card>
                                        </GridItem>
                                        :
                                        <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
                                            <HeaderMargin marginWithUnit={'6rem'}/>
                                            <form className={classes.form}>
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Email",
                                                        type: 'email',
                                                        value: email,
                                                        onChange: handleEmailChange,
                                                        error: emailError || (email !== '' && !isValidEmail(email))
                                                    }}
                                                />

                                                <div className={classes.textCenter}>
                                                    <Button color="primary" onClick={send}>
                                                        send
                                                    </Button>
                                                </div>

                                            </form>
                                            <div className={classes.textCenter}>
                                                <Danger>
                                                    {errorMessage}
                                                </Danger>
                                            </div>
                                        </GridItem>
                            }

                        </GridContainer>
                    </DialogContent>
                </Card>
            </Dialog>
        </div>
    );
}

export default ForgotTitheModal;
