import React from "react";
import InfoArea from "../../InfoArea/InfoArea";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import JCGridItem from "../grid/jc-grid-item";

const GetInTouch = ({ classes }) => (
    <JCGridItem xs={12} sm={5} md={5}>
        <h2 className={`${classes.title} ${classes.textCenter}`}>Get in Touch</h2>
        <h5 className={classes.description}>
            Do you need to get in touch with us physically?
            You're welcome to get in contact with us through the following means
            listed here
        </h5>
        <InfoArea
            className={classes.infoArea}
            title="Come by our office"
            description={
                <h3 style={{
                    color: 'white'
                }}>
                    Ringögatan 8A,
                    <br/> 417 07 Göteborg,
                    <br/> Sverige
                </h3>
            }
            icon={PinDrop}
        />
        <InfoArea
            className={classes.infoArea}
            title="Give us a call"
            description={
                <h3 style={{
                    color: 'white'
                }}>
                    +46 (0)31 220 845
                    <br/> Mon - Fri, 8:00-22:00
                </h3>
            }
            icon={Phone}
        />
    </JCGridItem>
)

export default GetInTouch;
