import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Button from "../../../CustomButtons/Button";
import CardFooter from "../../../Card/CardFooter";

const FormFooter = ({ classes, checked, setChecked, send }) => (
    <CardFooter className={classes.justifyContentBetween}>
        <FormControlLabel
            control={
                <Checkbox
                    tabIndex={-1}
                    onClick={() => setChecked(!checked)}
                    checkedIcon={
                        <Check className={classes.checkedIcon}/>
                    }
                    icon={<Check className={classes.uncheckedIcon}/>}
                    classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                    }}
                />
            }
            classes={{label: classes.label}}
            label="I'm not a robot"
        />
        <Button color="info" className={classes.pullRight} onClick={send}>
            Send Message
        </Button>
    </CardFooter>
)
export default FormFooter;
