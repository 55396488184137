import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";

import city from "images/socialmedia/postbox.jpg";
import JCGridContainer from "../grid/jc-grid-container";
import ContactForm from "./form/contact-form";
import GetInTouch from "./get-in-touch";

const useStyles = makeStyles(contactsStyle);

const ContactUs = () => {
    const classes = useStyles();
    return (
        <div className="cd-section">
            <div
                className={classes.contacts + " " + classes.section}
                style={{backgroundImage: `url(${city})`}}
            >
                <div className={classes.container}>
                    <JCGridContainer>
                        <GetInTouch classes={classes}/>
                        <ContactForm classes={classes}/>
                    </JCGridContainer>
                </div>
            </div>
        </div>
    );
}
export default ContactUs;
