import {
    ApolloClient,
    InMemoryCache
} from "@apollo/client";

export const API_URL = window.API_URL;

const client = new ApolloClient({
    uri: `${API_URL}/graphql`,
    cache: new InMemoryCache()
});


export default client;

