import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";

import giving from "images/giving/clay-banks-tgquMvhNLc4-unsplash.jpg";
import JCGridContainer from "../components/rccgjc/grid/jc-grid-container";
import JCGridItem from "../components/rccgjc/grid/jc-grid-item";

import {setThemeForHeader} from "../actions/app/appActions";
import {useDispatch} from "react-redux";
import Tithe from "../components/rccgjc/giving/tithe";

const useStyles = makeStyles(contactsStyle);

const TithePage = () => {

    const dispatch = useDispatch();
    useEffect(()=> {
            dispatch(setThemeForHeader('white'))
        },
        [])
    const classes = useStyles();
    return (
        <div className="cd-section">
            <div
                className={classes.contacts + " " + classes.section}
                style={{backgroundImage: `url(${giving})`}}
            >
                <div className={classes.container}>
                    <h2 className={`${classes.title} ${classes.textCenter}`}>Tithe</h2>
                    <h3 className={`${classes.description} ${classes.textCenter}`}>
                        Tithe is an important part of our giving. Tithe is a tenth(10%) of our income/profit.
                        Malachi 3:10 tells us to bring the whole tithe into the storehouse,
                        that there may be food in my house. Test me in this,” says the Lord Almighty,
                        “and see if I will not throw open the floodgates of heaven and pour out so much
                        blessing that there will not be room enough to store it.
                    </h3>
                </div>
            </div>
            <JCGridContainer justifyContent={'center'}>
                <JCGridItem>
                    <div className={classes.container}>
                        <div className={classes.textCenter}>
                            <h2 className={classes.title}>Ways to give</h2>
                        </div>
                        <Tithe/>
                    </div>
                </JCGridItem>
            </JCGridContainer>
        </div>
    );
}
export default TithePage;
