import React from "react";
import moment from "moment";
import './player.css'
import ReactPlayer from "react-player";
import GridItem from "../../Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import {Person,Schedule} from "@material-ui/icons";

import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";

const Mp3Player = ({ classes, link, speaker, title, date }) => (
    <GridItem
        xs={12}
        sm={8}
        md={8}
        className={
            classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
        }
    >
        <div>
            <Card profile plain>
                <CardBody>
                    <h2 className={'title'}>{title}</h2>
                    <Button simple color={'transparent'}>
                        <Person/>
                        {speaker}
                    </Button>

                    <Button simple color={'warning'}>
                        <Schedule/>
                        {moment(date).format("MMMM Do YYYY")}
                    </Button>
                    <div style={{
                        marginTop: '2.5rem'
                    }}>
                        <div className={'bg-black'}>
                            <ReactPlayer
                                url={link}
                                className='react-player'
                                playing={true}
                                width='100%'
                                height='100%'
                                controls={true}
                            />
                        </div>
                    </div>
                </CardBody>
            </Card>

        </div>

    </GridItem>
)
export default Mp3Player;
