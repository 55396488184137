import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import errorPageStyle from "assets/jss/material-kit-pro-react/views/errorPageStyles.js";

import gbg from "images/gbg/gbg1.jpg";

import chrome from 'images/IEErroe/chrome-7.svg';
import firefox from 'images/IEErroe/firefox-3.svg';
import safari from 'images/IEErroe/safari-3.svg';
import edge from 'images/IEErroe/microsoft-edge.svg';
import HeaderMargin from "../components/rccgjc/header-margin/header-margin";

const images = [
    {
        name: chrome,
        desc: "chrome"
    },
    {
        name: firefox,
        desc: "firefox"
    },
    {
        name: safari,
        desc: "safari"

    },
    {
        name: edge,
        desc: "edge"
    }
];

const useStyles = makeStyles(errorPageStyle);

const IEErrorPage = () => {

    const classes = useStyles();
    return (
        <div>
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + gbg + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.contentCenter}>
                    <GridContainer>
                        <GridItem md={12}>
                            <h2 className={classes.subTitle}>
                                You're using a version of Internet Explorer that's hard to write maintanable code for
                            </h2>
                            <h3 className={classes.description}>
                                We recommend the following browsers
                            </h3>
                        </GridItem>
                        <GridItem xs={12} sm={5} md={5}>
                            <HeaderMargin marginWithUnit={'2rem'}/>
                            {
                                <div style={{
                                    display: 'flex'
                                }}>
                                    {
                                        images.map((image, i) => {
                                            return (
                                                        <article key={i}>
                                                            <img
                                                                src={image.name}
                                                                alt={image.desc}
                                                                title={image.desc}
                                                                style={{
                                                                    width: '16rem',
                                                                    height: '4rem'
                                                                }}
                                                            />
                                                        </article>
                                            );
                                        })
                                    }
                                </div>
                            }
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}
export default IEErrorPage;
