import React, {Fragment} from "react";
import InfoArea from "../../../InfoArea/InfoArea";
import Timeline from "@material-ui/icons/NotificationImportant";
import Button from "components/CustomButtons/Button.js";
import CustomLinearProgress from "../../../CustomLinearProgress/CustomLinearProgress";

const NewsletterLoading = ({ classes, clear }) => (
    <Fragment>
        <InfoArea
            className={classes.infoArea}
            title="one moment please..."
            description={
                <CustomLinearProgress
                    variant="indeterminate"
                    color="info"
                    value={60}
                />
            }
            icon={Timeline}
            iconColor="info"
        />
        <div className={classes.textCenter}>
            <Button color={'info'} onClick={() => clear()}>
                Close
            </Button>
        </div>
    </Fragment>
)
export default NewsletterLoading;
