import React, {useEffect, useState} from "react";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {Link} from "react-router-dom";
import JCFooterRoot from "./jc-footer-root";
import JCSubscribeToNewsletter from "../modals/jc-subscribe-to-newsletter";
import LandingPageModal from "../landingpage-status/landing-page-carousal-modal";
import ForgotTitheModal from "../giving/forgot-tithe-number-modal";
import {
    useQuery
} from '@apollo/client';

import {LANDING_PAGE} from "../../../graphql-config/query/landing_page";
import generatedGitInfo from 'generatedGitInfo.json'

const useStyles = makeStyles(landingPageStyle);


const JCFooter = () => {
    const {data, loading, error} = useQuery(LANDING_PAGE)

    const [details, setDetails] = useState({});

    useEffect(()=> {
        if (!loading && !error) {
            setDetails(data.landingPageDetails)
        }
    }, [data])

    const classes = useStyles();
    return (
        <JCFooterRoot
            content={
                <div>
                    <div className={classes.left}>
                        <List className={classes.list}>
                            <ListItem className={classes.inlineBlock}>
                                <Link
                                    to={'/'}
                                    className={classes.block}
                                >
                                    rccgjc
                                </Link>
                            </ListItem>

                            <ListItem className={classes.inlineBlock}>
                                <Link
                                    to={'/privacy'}
                                    className={classes.block}
                                >
                                    Privacy
                                </Link>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a href="mailTo:info@rccgjc.com" className={classes.blockLower}>
                                    info@rccgjc.com
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <JCSubscribeToNewsletter simple/>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <ForgotTitheModal/>
                            </ListItem>
                            {
                                 details.status && details.details.length > 0?
                                    <ListItem className={classes.inlineBlock}>
                                        <LandingPageModal details={details}/>
                                    </ListItem>
                                    :
                                    null
                            }
                        </List>
                    </div>
                    <div className={classes.right}>
                        &copy; {1900 + new Date().getYear()} {" "}
                        <Link
                            style={{
                                textDecoration: 'none',
                                color: 'black'
                            }}
                            to={'/'}
                        >
                            rccgjesusconnections
                        </Link>
                        <p>version: {generatedGitInfo.gitTag}</p>
                    </div>
                </div>
            }
        />
    )
}
export default JCFooter
